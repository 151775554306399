import React, { useContext } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Badge } from "reactstrap";
import { numberToCLPFormater } from 'numbertoclpformater'
import { useCookies } from 'react-cookie';

import PromotionProvider, { PromotionContext } from '@Contexts/promotion'
import FormProvider, { FormContext } from '@Contexts/form'
import { getNumber, getCancelAlert, getSuccessAlert, getWarningAlert, infoBody } from '@Config/util'

import './style.css'

const Promotions = ({ history }) => {
    const
        { promotions, removePromotion } = useContext(PromotionContext),
        { values: { MyAlert }, setMyStates } = useContext(FormContext),
        [{ userData }, ,] = useCookies(['userData']),
        remove = ({ id, name, price }) => () => setMyStates({ MyAlert: getWarningAlert({ title: "¿Está seguro de eliminar la promoción?", confirm: () => confirmRemove(id), cancel: () => setMyStates({ MyAlert: getCancelAlert({hideAlert}) }), body: infoBody({ name, price: numberToCLPFormater(getNumber(price)) }) }) }),
        confirmRemove = async _id => {
            const { ok, msg } = await removePromotion({ _id })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La promoción ha sido eliminado!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
        },
        hideAlert = () => setMyStates({ MyAlert: null }),
        getData = () => {
            return promotions.map((data) => {
                    const { name, price, _id, selected, wrappers, products } = data
                    return {
                        id: _id,
                        name: name,
                        price: numberToCLPFormater(price),
                        selected: selected ? "Si" : "No",
                        wrappers:
                            <div className="d-flex flex-column">
                                {
                                    wrappers.map(({ wrapper: { name }, amount }, k) =>
                                        <Badge key={k} color="success">{name} {amount}</Badge>
                                    )
                                }
                            </div>
                        ,
                        products:
                            <div className="d-flex flex-column">
                                {
                                    products.map(({ product: { name }, amount }, k) =>
                                        <Badge key={k} color="primary">{name} {amount}</Badge>
                                    )
                                }
                            </div>,
                        actions:
                            <div className="actions-right">
                                <Button onClick={() => history.push({ pathname: '/app/promotion/modify/' + _id, state: data })} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                                <Button onClick={remove({ name, price, id: _id })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                    }
                })
        },
        getColumns = () => {
            let columns = [
                
                    {

                        Header: "Nombre",
                        accessor: "name",
                        className: "text-center"
                    },
                    {
                        Header: "Precio",
                        accessor: "price",
                        className: "mx-2 text-right"

                    },
                    {
                        Header: "Elección del chef",
                        accessor: "selected",
                        className: "text-center"

                    },
                    {
                        Header: "Envolturas",
                        accessor: "wrappers",
                        filterable: false
                    },
                    {
                        Header: "Productos",
                        accessor: "products",
                        filterable:false
                    }]
                    if (userData.type === "administrador")
                    columns.push({
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        Footer:
                            <Row>
                                <Col md={6} className="text-center" >
                                    <Button size="sm" onClick={() => history.push('/app/promotion/add')}>Crear nuevo</Button>
                                </Col>
                            </Row>
                 })
                 return columns;
             }
        

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de promociones</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColumns()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (


    <PromotionProvider>
        <FormProvider initialValues={{ MyAlert: null }}>
            <Promotions history={history} />
        </FormProvider>
    </PromotionProvider>
)