export const weightTableConfig = {
    filterable: true,
    columns: [
        {
            Header: "Nombre M. Primas",
            accessor: ({ name, unity }) => `${name} (${unity})`,
            className: "mx-2 text-center",
            id: "named"
        },
        {
            Header: "Total Primas",
            accessor: 'weight',
            className: "text-right",
        },

    ],
    defaultPageSize: 5,
    showPaginationBottom: true,
    className: "-striped -highlight",
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando...',
    noDataText: 'No existen datos',
    pageText: 'Página',
    ofText: 'de',
    rowsText: 'elementos'
}