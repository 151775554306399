import React, { useEffect, useContext } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import UserProvider, { UserContext } from '@Contexts/user'
import FormProvider, { FormContext } from '@Contexts/form'
import { useCookies } from 'react-cookie';

import { $logo } from '../../config'

const Login = ({ history }) => {

  const { login } = useContext(UserContext),
    { handleInputChange, values: { email, password } } = useContext(FormContext)
    const [{ userData }, ,] = useCookies(['userData']);

  useEffect(() => {

   document.body.classList.toggle("login-page");
    return () => {
      document.body.classList.toggle("login-page");
      if(userData){
        if(userData.type || userData.token){
       return history.push('/app/dashboard')
        }
      }
    
    }
  })

  
  const submit = async e => {
    e.preventDefault()
    await login({ email, password })
    redir()    
  }

  const redir = () => {
    history.push('/app/dashboard')
    if ({userData}) return history.push('/app/dashboard')
    if (userData.type || userData.token) return history.push('/app/dashboard')
   
  }

  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col className=" my-auto mb-auto ml-auto mr-auto mt-auto mx-auto ml-auto" md="7">

              <Card className="card-lock card-black text-center ">
                <CardHeader>
                  <CardImg
                    alt="..."
                    src={`/${$logo()}`}
                  />
                 
                </CardHeader>
                <Form className="form" onSubmit={submit}>
                  <CardBody className="text-center py-5 px-0">

                    <Row>

                      <Col className="mr-auto ml-auto text-center" md="6">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Correo electrónico" name="email" type="email" value={email} onChange={handleInputChange} required/>
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Contraseña" name="password" type="password" value={password} onChange={handleInputChange} required />
                        </InputGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="success"
                      type="submit"
                      size="lg"
                    >
                      Iniciar Sesión
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ({ history }) => (
  <UserProvider>
    <FormProvider initialValues={{ email: '', password: '' }}>
      <Login history={history} />
    </FormProvider>
  </UserProvider>
)