import React, { useContext, useState } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Input, Badge } from "reactstrap";
import { numberToCLPFormater } from 'numbertoclpformater'
import Switch from "react-bootstrap-switch";
import { useCookies } from 'react-cookie';

import Modal from '../../components/Modal.js';

import ProductProvider, { ProductContext } from '@Contexts/product'
import FormProvider, { FormContext } from '@Contexts/form'
import { getNumber, getCancelAlert, getSuccessAlert, getWarningAlert, infoBody, isWeight, getIndexFromArray } from '@Config/util'

const Products = () => {
    const { products, modifyProduct, removeProduct, addProduct } = useContext(ProductContext),
        { values: { editBarcode, editFavorite, editId, editName, editPrice, editWeight, editWeights, createBarcode, createName, createPrice, createWeight, createWeights, createNew, createFavorite, MyAlert }, handleInputChange, setMyStates } = useContext(FormContext),
        [{ userData }, ,] = useCookies(['userData']);
    const [showModal, setModal] = useState(false);
    const
        remove = ({ id, name, price }) => () => setMyStates({ MyAlert: getWarningAlert({ title: "¿Está seguro de eliminar el producto?", confirm: () => confirmRemove(id), cancel: () => setMyStates({ MyAlert: getCancelAlert({ hideAlert }) }), body: infoBody({ name, price: numberToCLPFormater(getNumber(price)) }) }) }),
        confirmRemove = async _id => {
            const { ok, msg } = await removeProduct({ _id })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "El producto ha sido eliminado!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
        },
        modify = (id, name, price, weight, favorite, barcode) => () => setMyStates({ editBarcode: barcode, editId: id, editName: name, editPrice: numberToCLPFormater(getNumber(price)), editWeight: '', editWeights: weight, editFavorite: favorite }),
        confirmModify = async () => {
            const { ok, msg } = await modifyProduct({ barcode: editBarcode, name: editName, price: getNumber(editPrice), _id: editId, weight: editWeights, favorite: editFavorite })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "El producto ha sido modificado!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) setMyStates({ editId: "", editName: "", editPrice: "", editWeight: "", editWeights: [], editFavorite: false })
        },
        cancelModify = () => setMyStates({ editBarcode: "", editId: "", editName: "", editPrice: "", editWeight: "", editWeights: [], editFavorite: false }),
        hideAlert = () => setMyStates({ MyAlert: null }),
        beforChange = ({ target: { value, name } }) => handleInputChange({ target: { value: numberToCLPFormater(getNumber(value)), name } }),
        createProduct = () => setMyStates({ createBarcode: "", createName: "", createPrice: "", createWeight: "", createWeights: [], createNew: true, createFavorite: false }),
        confirmCreate = async () => {
            const { ok, msg } = await addProduct({ barcode: createBarcode, name: createName, price: getNumber(createPrice), weight: createWeights, favorite: createFavorite })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "El producto ha sido creado!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) cancelCreate()
        },
        cancelCreate = () => setMyStates({ createBarcode: "", createName: "", createPrice: "", createWeight: "", createWeights: [], createNew: false }),
        changeWeight = ({ target: { value, name } }) => {
            if (isWeight(value)) {
                const myWeight = name === "createWeight" ? createWeights : editWeights
                return setMyStates({ [name + 's']: myWeight.concat(isWeight(value)), [name]: '' })
            }
            handleInputChange({ target: { value, name } })
        },
        deleteWeight = ({ from, data }) => () => {
            const index = getIndexFromArray(from === "createWeights" ? createWeights : editWeights, data, ['amount', 'unity', 'name'])
            setMyStates({
                [from]: from === "createWeights" ?
                    createWeights.slice(0, index).concat(createWeights.slice(index + 1)) :
                    editWeights.slice(0, index).concat(editWeights.slice(index + 1))
            })
        },
        getData = () => {
            return products.map(({ name, price, _id, weight, favorite, barcode }) => {
                return {
                    id: _id,
                    name: (editId === _id) ?
                        <Input className="text-right" name="editName" placeholder="Nombre" onChange={handleInputChange} value={editName} /> :
                        name,
                    barcode: (editId === _id) ?
                        <div className="d-flex">
                            <Input className="text-right" name="editBarcode" placeholder="Código de barras" onChange={handleInputChange} value={editBarcode} />
                            <Button onClick={() => setModal(true)} className="btn-icon btn-link ml-2">
                                <i className="tim-icons icon-camera-18 text-gray" />
                            </Button>
                        </div>
                        :
                        barcode,
                    favorite: (editId === _id) ?
                        <Switch
                            offText={<i className="tim-icons icon-simple-remove" />}
                            onText={<i className="tim-icons icon-check-2" />}
                            value={editFavorite}
                            onChange={({ props: { name } }, value) => handleInputChange({ target: { value, name } })}
                            name="editFavorite" /> :
                        (favorite ? "Si" : "No"),
                    price: (editId === _id) ?
                        <Input className="text-right" name="editPrice" placeholder="Precio" onChange={beforChange} value={editPrice} /> :
                        numberToCLPFormater(price),
                    weight: (editId === _id) ?
                        <Row>
                            <Col md={editWeights.length >= 1 ? 6 : 1} className="d-flex flex-column">
                                {editWeights.map(({ name, unity, amount }, k) => (
                                    <Badge key={k} href="#" onClick={deleteWeight({ from: 'editWeights', data: { name, unity, amount } })}
                                        color="success">{name} {amount}{unity}</Badge>)
                                )}
                            </Col>
                            <Col md={editWeights.length >= 1 ? 6 : 11}>
                                <Input name="editWeight" value={editWeight} placeholder="Gramaje (ej: palta:50gr,salmon:30gr)" onChange={changeWeight} />
                            </Col>
                        </Row>
                        :
                        <div className="d-flex flex-column">
                            {weight.map(({ name, unity, amount }, k) => (
                                <Badge key={k} color="primary">{name} {amount}{unity}</Badge>
                            ))}
                        </div>,
                    actions:
                        (editId === _id) ?
                            <div className="actions-right">
                                <Button onClick={confirmModify} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-check-2 bg-success" />
                                </Button>
                                <Button onClick={cancelModify} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                            :
                            <div className="actions-right">
                                <Button onClick={modify(_id, name, price, weight, favorite, barcode)} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                                <Button onClick={remove({ name, price, id: _id })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                }
            })
        },
        getColumns = () => {
            let columns = [
                {

                    Header: "Nombre",
                    accessor: "name",
                    className: "text-center",
                    Footer: createNew ?
                        <Input name="createName" value={createName} placeholder="Nombre" onChange={handleInputChange} /> :
                        null
                },
                {

                    Header: "Codigo de Barras",
                    accessor: "barcode",
                    className: "text-center",
                    Footer: createNew ?
                        <div className="d-flex">
                            <Input name="createBarcode" value={createBarcode} placeholder="Codigo de Barras" onChange={handleInputChange} />
                            <Button onClick={() => setModal(true)} className="btn-icon btn-link ml-2">
                                <i className="tim-icons icon-camera-18 text-gray" />
                            </Button>
                        </div>
                        :
                        null
                },
                {

                    Header: "¿Destacado?",
                    accessor: "favorite",
                    className: "text-center",
                    Footer: createNew ?
                        <Switch
                            offText={<i className="tim-icons icon-simple-remove" />}
                            onText={<i className="tim-icons icon-check-2" />}
                            value={createFavorite}
                            onChange={({ props: { name } }, value) => handleInputChange({ target: { value, name } })}
                            name="createFavorite" /> :
                        null
                },
                {
                    Header: "Precio",
                    accessor: "price",
                    className: "mx-2 text-right",
                    Footer: createNew ? <Input name="createPrice" className="text-right" value={createPrice} placeholder="Precio" onChange={beforChange} /> :
                        null

                },
                {
                    Header: "Gramaje",
                    accessor: "weight",
                    className: "text-center",
                    filterable: false,
                    Footer: createNew ?
                        <Row>
                            <Col md={createWeights.length >= 1 ? 6 : 1} className="d-flex flex-column">
                                {createWeights.map(({ name, unity, amount }, k) => (
                                    <Badge key={k} href="#" onClick={deleteWeight({ from: 'createWeights', data: { name, unity, amount } })}
                                        color="success">{name} {amount}{unity}</Badge>)
                                )}
                            </Col>
                            <Col md={createWeights.length >= 1 ? 6 : 11}>
                                <Input name="createWeight" value={createWeight} placeholder="Gramaje (ej: palta:50gr,salmon:30gr)" onChange={changeWeight} />
                            </Col>
                        </Row> :
                        null
                }]
            if (userData.type === "administrador")
                columns.push({
                    Header: "Acciones",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Footer:
                        <Row>
                            {createNew ?
                                <>
                                    <Col md={6}>
                                        <Button size="sm" color="success" onClick={confirmCreate}>Guardar</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button size="sm" color="danger" onClick={cancelCreate}>Cancelar</Button>
                                    </Col>
                                </>
                                :
                                <Col md={6} >
                                    <Button size="sm" onClick={createProduct}>Crear nuevo</Button>
                                </Col>
                            }
                        </Row>
                })
            return columns;
        }

    const onErrorScan = () => {
        setMyStates({ MyAlert: getCancelAlert({ hideAlert, msg: "Error al leer código de barras." }) });
    }

    const onScan = prod => {
        setModal(false);
        setMyStates({ MyAlert: getSuccessAlert({ hideAlert, msg: "Producto escaneado correctamente!." }), createBarcode: prod, editBarcode: prod });
    }

    return (
        <div className="content">
            {MyAlert}
            <Modal showModal={showModal} setModal={setModal} onErrorScan={onErrorScan} onScan={onScan} />
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de productos</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColumns()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (
    <ProductProvider>
        <FormProvider initialValues={{ editBarcode: "", editFavorite: false, editId: "", editName: "", editPrice: "", editWeight: "", editWeights: [], createName: "", createPrice: "", createWeight: "", createWeights: [], createNew: false, crateFavorite: false, createBarcode: "", MyAlert: null }}>
            <Products history={history} />
        </FormProvider>
    </ProductProvider>
)