import React, { useContext, useEffect, useState } from 'react'
import ReactTable from "react-table";
import Select from "react-select"
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Label, FormGroup, Input } from "reactstrap";
import { useCookies } from 'react-cookie';
import { numberToCLPFormater } from 'numbertoclpformater'

import SellProvider, { SellContext } from '@Contexts/sell'
import FormProvider, { FormContext } from '@Contexts/form'
import CashierBalancing from '@Components/CashierBalancing'

import { getDataRow, getHeaders } from '@Config/sell.config'
import { getCancelAlert, getNumber, getTip } from '@Config/util';


const Sells = ({ history, params: { page = "1" } }) => {
    const
        { sells, modifySell, getSells, loading, getCashierBalancing, cashierBalancing } = useContext(SellContext),
        { values: { MyAlert, editPayment, editId, editState, editAmount, editTip, states }, setMyStates, handleInputChange } = useContext(FormContext),
        [{ userData }, ,] = useCookies(['userData']),
        [ready, setReady] = useState(false);

    useEffect(() => {
        async function start() {
            setReady(true)
            await getSells(parseInt(page));
            await getCashierBalancing();
        }
        if (!ready) start()

    }, [getSells, page, ready, getCashierBalancing])

    const
        confirmModify = async () => {
            const { ok, msg } = await modifySell({ _id: editId, payment: editPayment.value, state: editState.value, tip: { type: editTip.value, amount: editAmount } })
            if (!ok)
                setMyStates({ MyAlert: getCancelAlert({ hideAlert: () => setMyStates({ MyAlert: null }), msg }) })
            else
                setMyStates({ editId: "", editAmount: 0, editPayment: { value: "", label: "" }, editState: { label: "", value: "" }, editTip: { label: "", value: "" } })
        },
        changeCheckBox = ({ target: { name } }) => setMyStates({ states: { ...states, [name]: !states[name] } }),
        getOptions = () => {
            let options = [
                { label: "en cocina", value: "en cocina" }, { label: "en tránsito", value: "en tránsito" }, { label: "entregado", value: "entregado" }
            ]
            if (userData.type === "administrador") options.push({ label: "cancelada", value: "cancelada" })
            return options
        },
        getData = () => sells.map((data) => {
            const { _id, state, tip, payment } = data
            return {
                ...getDataRow(data),
                state: _id === editId ? <Select className="react-select info text-center" classNamePrefix="react-select"
                    name="editState" value={editState} onChange={(e) => handleInputChange({ target: { name: "editState", value: e } })} menuPosition="fixed" options={getOptions()} placeholder="Selecciona el estado..." /> : state,
                payment: _id === editId ? (
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        value={editPayment}
                        menuPosition="fixed"
                        onChange={v => setMyStates({ editPayment: v })}
                        options={[
                            { value: "debito", label: "Débito" }, { value: "credito", label: "Crédito" }, { value: "efectivo", label: "Efectivo" },
                            { value: "transferencia", label: "Transferencia" }, { value: "cheque", label: "Cheque" }, { value: "rappi", label: "Rappi" }
                        ]}
                        placeholder="Seleccione el método de pago"
                    />
                ) : payment,
                tip: _id === editId ? (
                    <FormGroup className="d-flex flex-column">
                        <label> Tipo de propina:</label>
                        <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            value={editTip}
                            menuPosition="fixed"
                            onChange={(e) => setMyStates({ editTip: e })}
                            options={[
                                { value: "cash", label: "Efectivo" },
                                { value: "transbank", label: "Transbank" },
                                { value: "transfer", label: "Transferencia" }
                            ]}
                            placeholder="Seleccione el método propina"
                        />
                        <label> Monto de propina:</label>
                        <Input value={numberToCLPFormater(editAmount)}
                            onChange={({ target: { value } }) => setMyStates({ editAmount: getNumber(value) })} />
                    </FormGroup>
                ) : <div>{getTip(tip.type)}:{tip.amount}</div>,

                actions:
                    editId === _id ?
                        <div className="actions-right">
                            <Button onClick={confirmModify} className="btn-icon btn-link like mr-2"> <i className="tim-icons icon-check-2 bg-success" /> </Button>
                            <Button onClick={() => setMyStates({ editId: "", editAmount: 0, editPayment: { label: "", value: "" }, editState: { label: "", value: "" } })} className="btn-icon btn-link like"><i className="tim-icons icon-simple-remove bg-danger" /> </Button>
                        </div>
                        :
                        <div>
                            <Button onClick={() => history.push({ pathname: '/print', state: data })} className="btn-icon btn-link like"> <i className="tim-icons icon-paper bg-warning" /> </Button>
                            <Button onClick={() => setMyStates({ editTip: { label: getTip(tip.type), value: tip.type }, editAmount: tip.amount, editPayment: { value: payment, label: payment }, editState: { label: state, value: state }, editId: _id })} className="btn-icon btn-link like"> <i className="tim-icons icon-pencil bg-success" /> </Button>
                        </div>
            }
        })
    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Ventas</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <CashierBalancing
                                        cashierBalancing={cashierBalancing}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Columnas a mostrar</Label>
                                <Col sm="10">
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDelivery" checked={states.stateDelivery} onChange={changeCheckBox} /><span className="form-check-sign" />Delivery</Label>                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateWrappers" checked={states.stateWrappers} onChange={changeCheckBox} /><span className="form-check-sign" />Rolls</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDate" checked={states.stateDate} onChange={changeCheckBox} /><span className="form-check-sign" />Fecha</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="statePayment" checked={states.statePayment} onChange={changeCheckBox} /><span className="form-check-sign" />Tipo pago</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateClient" checked={states.stateClient} onChange={changeCheckBox} /><span className="form-check-sign" />Cliente</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateUser" checked={states.stateUser} onChange={changeCheckBox} /><span className="form-check-sign" />Usuario</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateProducts" checked={states.stateProducts} onChange={changeCheckBox} /><span className="form-check-sign" />Productos</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="statePromotions" checked={states.statePromotions} onChange={changeCheckBox} /><span className="form-check-sign" />Promociones</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateNote" checked={states.stateNote} onChange={changeCheckBox} /><span className="form-check-sign" />Nota</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateOut" checked={states.stateOut} onChange={changeCheckBox} /><span className="form-check-sign" />Hora entrega</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDiscount" checked={states.stateDiscount} onChange={changeCheckBox} /><span className="form-check-sign" />Descuento</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateTip" checked={states.stateTip} onChange={changeCheckBox} /><span className="form-check-sign" />Propina</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <ReactTable
                                        loading={loading}
                                        data={getData()}
                                        filterable
                                        columns={getHeaders(states)}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        previousText='Anterior'
                                        nextText='Siguiente'
                                        loadingText='Cargando...'
                                        noDataText='No existen datos'
                                        pageText='Página'
                                        ofText='de'
                                        page={!page ? 0 : page - 1}
                                        rowsText='elementos'
                                        PaginationComponent={
                                            () => {
                                                return <div className="-pagination" bis_skin_checked="1">
                                                    <div className="-previous" bis_skin_checked="1">
                                                        <button
                                                            type="button"
                                                            disabled={page === "1" ? true : false}
                                                            className="-btn"
                                                            onClick={() => {
                                                                history.replace('/app/sells/' + (parseInt(page) - 1))
                                                            }}
                                                        >Anterior</button>
                                                    </div>
                                                    <div className="-center" />
                                                    <div className="-next" bis_skin_checked="1">
                                                        <button
                                                            type="button"
                                                            className="-btn"
                                                            disabled={loading}
                                                            onClick={async () => {
                                                                await getSells(parseInt(page) + 1)
                                                                history.replace('/app/sells/' + (parseInt(page) + 1))
                                                            }}
                                                        >Siguiente</button>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history, match: { params } }) => (

    <SellProvider>
        <FormProvider initialValues={{ MyAlert: null, editAmount: 0, editId: "", editPayment: { label: "", value: "" }, editTip: { label: "", value: "" }, editState: { label: "", value: "" }, states: { stateTip: true, stateDelivery: false, stateWrappers: false, stateDate: false, statePayment: true, stateClient: true, stateUser: false, stateProducts: false, statePromotions: false, stateDiscount: false } }}>
            <Sells history={history} params={params} />
        </FormProvider>
    </SellProvider>
)