import React from 'react'
import ReactExport from "react-export-excel";
import { Button } from "reactstrap";
import moment from 'moment'
import { numberToCLPFormater } from 'numbertoclpformater'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default ({ cashierBalancing, loading }) => {

    const getData = () => {
        return [
            {
                columns: ["Total", "Tipo", "", "Caja Inicial", "", "FECHA"], width: { wpx: 400 },
                data: [
                    [numberToCLPFormater(parseInt(cashierBalancing.debit)), "Debito", "", '$30.000', "", moment().format('DD-MM-Y')],
                    [numberToCLPFormater(parseInt(cashierBalancing.cash)), "Efectivo"],
                    [numberToCLPFormater(parseInt(cashierBalancing.credit)), "Credito"],
                    [numberToCLPFormater(parseInt(cashierBalancing.transfer)), "Transferencia"],
                    [numberToCLPFormater(parseInt(cashierBalancing.rappi)), "Rappi"],
                    [numberToCLPFormater(parseInt(cashierBalancing.check)), "Cheque"],
                    [numberToCLPFormater(parseInt(cashierBalancing.total)), "Total"],
                    [cashierBalancing.discounts, "Cantidad de descuentos"],
                    [numberToCLPFormater(parseInt(cashierBalancing.totalDiscount)), "Suma de descuentos"],
                    [numberToCLPFormater(parseInt(cashierBalancing.tip.transfer)), "Propinas transferencias"],
                    [numberToCLPFormater(parseInt(cashierBalancing.tip.transbank)), "Propinas transbank"],
                    [numberToCLPFormater(parseInt(cashierBalancing.tip.cash)), "Propinas efectivo"],
                    [numberToCLPFormater(parseInt(cashierBalancing.tip.transfer) + parseInt(cashierBalancing.tip.transfer) + parseInt(cashierBalancing.tip.cash) ), "Propinas totales"]
                ]
            },
            {
                xSteps: 1, // Will start putting cell with 1 empty cell on left most
                ySteps: 5, //will put space of 5 rows,
                columns: ["Caja Inicial", "Cantidad", "Total", "", "Documento Venta", "Desde", "Hasta", "Valor"],
                data: [
                    [{ value: "$30.000", style: { fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } } } },],
                    ["", "$20.000", "", "", "", "", "", "$"],
                    ["", "$10.000", "", "", "", "", "", "$"],
                    ["", "$5.000", "", "", "", "", "", "$"],
                    ["", "$2.000", "", "", "", "", "", "$"],
                    ["", "$1.000", "", "", "", "", "", "$"],
                    ["", "500", "", "", "", "", "", "$"],
                    ["", "$100", "", "", "", "", "", "$"],
                    ["", "$50", "", "", "", "", "", "$"],
                    ["", "$10", "", "", "", "", "", "$"]
                ]
            },
            {
                xSteps: 1, // Will start putting cell with 1 empty cell on left most
                ySteps: 5, //will put space of 5 rows,
                columns: ["Caja Entregada", "Cantidad", "Total", "", "Detalles", "Valores", "Cant. Doc."],
                data: [
                    ["", "$20.000", "", "", "TRANSFERENCIA", "$", ""],
                    ["", "$10.000", "", "", "CHEQUES", "$", ""],
                    ["", "$5.000", "", "", "TRANSBANK DESPACHO", "$", ""],
                    ["", "$2.000", "", "", "TRANSBANK LOCAL", "$", ""],
                    ["", "$1.000", "", "", "SUB- TOTAL", "$", ""],
                    ["", "500", "", "", "MENOS CAJA SENCILLO", "$30.000"],
                    ["", "$100", "", "", "TOTAL VENTA", "$", ""],
                    ["", "$50", "", "", "DIFERENCIA NO DOCUMENTADA", "$", ""],
                    ["", "$10", "", "", "DIFERENCIA CON SISTEMA", "$", ""]
                ]
            },
            {
                xSteps: 1, // Will start putting cell with 1 empty cell on left most
                ySteps: 5, //will put space of 5 rows,
                columns: ["Ventas con Sistema", "Cantidad", "Total", "", "EGRESOS DIARIO", "VALOR", "N° DOC", "NOMBRE/DETALLE"],
                data: [
                    ["", "", "", "", "ANTICIPOS", "$", "", ""],
                    ["", "", "", "", "ANTICIPOS", "$", "", ""],
                    ["", "", "", "", "ANTICIPOS", "$", "", ""],
                    ["", "", "", "", "BOLETA", "$", "", ""],
                    ["", "", "", "", "FACTURA", "$", "", ""],
                    ["", "", "", "", "BOLETA", "$", "", ""],
                    ["", "", "", "", "FACTURA", "$", "", ""],
                    ["", "", "", "", "SUB-TOTAL", "$", "", ""]
                ]
            },
            {
                xSteps: 1, // Will start putting cell with 1 empty cell on left most
                ySteps: 5, //will put space of 5 rows,
                columns: ["RESUMEN CAJA", "TOTAL"],
                data: [
                    ["SALDO EFECTIVO", "$"],
                    ["MENOS CAJA SENCILLO", "$30.000"],
                    ["SUB-TOTAL A ENTREGAR", "$"]
                ]
            },
        ];
    }
    return <ExcelFile
        element={<Button disabled={loading}>Generar Cierre de Caja</Button>}
        filename={"Cierre de Caja " + moment().format('DD/MM/YYYY')}
    >
        <ExcelSheet dataSet={getData()} name="Organization" />
    </ExcelFile>
}