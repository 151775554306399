
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import AdminLayout from "./layouts/Admin/Admin";
import AuthLayout from "./layouts/Auth/Auth";
import Printer from './views/Printer'
import { $graphql } from '@Config'

// product scss
import "./assets/css/nucleo-icons.css";
import "./assets/scss/black-dashboard-pro-react.scss";
import "./assets/demo/demo.css";
import "./react-notification-alert/dist/animate.css";

const hist = createBrowserHistory(),
  link = new HttpLink($graphql()),
  cache = new InMemoryCache({ dataIdFromObject: object => object.id || null, addTypename: false })

const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  request: (operation) => {
    operation.setContext({
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000'
      }
    })
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/app" render={props => <AdminLayout {...props} />} />
        <Route path="/print" component={Printer} />
        <Redirect from="/" to="/app/dashboard" />
      </Switch>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);
