import React, { useContext, useState } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Label, FormGroup, Input, Button } from "reactstrap";
import Autosuggest from 'react-autosuggest';
import ReactTable from "react-table";
import Select from "react-select"
import { useCookies } from 'react-cookie';

import SellProvider, { SellContext } from '@Contexts/sell'
import FormProvider, { FormContext } from '@Contexts/form'
import ClientProvider, { ClientContext } from '@Contexts/client'
import { getDataRow, getHeaders } from '@Config/sell.config'
import { getCancelAlert, getNumber, getTip } from '@Config/util';
import { numberToCLPFormater } from 'numbertoclpformater'

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
const cleanUp = val => val.toString().toLowerCase().trim()
const getSuggestions = (value, arr) => {
    const escapedValue = escapeRegexCharacters(cleanUp(value));
    if (escapedValue === '') { return []; }
    const regex = new RegExp('^' + escapedValue, 'i');
    return arr.filter(({ name, email, phone, address }) => (regex.test(cleanUp(name)) || regex.test(cleanUp(phone)) || regex.test(cleanUp(email)) || regex.test(cleanUp(address))));
}

const Search = ({ history }) => {
    const
        { setMyStates, handleInputChange, values: { MyAlert, editPayment, editId, editState, editAmount, editTip, stateTip, stateDelivery, stateWrappers, stateDate, statePayment, stateClient, stateUser, stateProducts, statePromotions, stateDiscount, stateNote, stateOut } } = useContext(FormContext),
        { modifySell, getByIndex, getFilteredSells, sellsFiltered, loading } = useContext(SellContext),
        { clients } = useContext(ClientContext),
        [suggest, setSuggest] = useState(""),
        [suggests, setSuggests] = useState([]),
        [{ userData }, ,] = useCookies(['userData']),
        getOptions = () => {
            let options = [
                { label: "en cocina", value: "en cocina" }, { label: "en tránsito", value: "en tránsito" }, { label: "entregado", value: "entregado" }
            ]
            if (userData.type === "administrador") options.push({ label: "cancelada", value: "cancelada" })
            return options
        },
        states = { stateTip, stateDelivery, stateWrappers, stateDate, statePayment, stateClient, stateUser, stateProducts, statePromotions, stateDiscount, stateNote, stateOut },
        changeCheckBox = ({ target: { name } }) => setMyStates({ [name]: !states[name] }),
        confirmModify = async () => {
            const { ok, msg } = await modifySell({ _id: editId, payment: editPayment.value, state: editState.value, tip: { type: editTip.value, amount: editAmount } })
            if (!ok)
                setMyStates({ MyAlert: getCancelAlert({ hideAlert: () => setMyStates({ MyAlert: null }), msg }) })
            else
                setMyStates({ editId: "", editAmount: 0, editPayment: { value: "", label: "" }, editState: { label: "", value: "" }, editTip: { label: "", value: "" } })
        },
        getData = () => sellsFiltered.map((data) => {
            const { _id, state, tip, payment } = data
            return {
                ...getDataRow(data),
                state: _id === editId ? <Select className="react-select info text-center" classNamePrefix="react-select"
                    name="editState" value={editState} onChange={(e) => handleInputChange({ target: { name: "editState", value: e } })} menuPosition="fixed" options={getOptions()} placeholder="Selecciona el estado..." /> : state,
                payment: _id === editId ? (
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        value={editPayment}
                        menuPosition="fixed"
                        onChange={v => setMyStates({ editPayment: v })}
                        options={[
                            { value: "debito", label: "Débito" }, { value: "credito", label: "Crédito" }, { value: "efectivo", label: "Efectivo" },
                            { value: "transferencia", label: "Transferencia" }, { value: "cheque", label: "Cheque" }, { value: "rappi", label: "Rappi" }
                        ]}
                        placeholder="Seleccione el método de pago"
                    />
                ) : payment,
                tip: _id === editId ? (
                    <FormGroup className="d-flex flex-column">
                        <label> Tipo de propina:</label>
                        <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            value={editTip}
                            menuPosition="fixed"
                            onChange={(e) => setMyStates({ editTip: e })}
                            options={[
                                { value: "cash", label: "Efectivo" },
                                { value: "transbank", label: "Transbank" },
                                { value: "transfer", label: "Transferencia" }
                            ]}
                            placeholder="Seleccione el método propina"
                        />
                        <label> Monto de propina:</label>
                        <Input value={numberToCLPFormater(editAmount)}
                            onChange={({ target: { value } }) => setMyStates({ editAmount: getNumber(value) })} />
                    </FormGroup>
                ) : <div>{getTip(tip.type)}:{tip.amount}</div>,

                actions:
                    editId === _id ?
                        <div className="actions-right">
                            <Button onClick={confirmModify} className="btn-icon btn-link like mr-2"> <i className="tim-icons icon-check-2 bg-success" /> </Button>
                            <Button onClick={() => setMyStates({ editId: "", editAmount: 0, editPayment: { label: "", value: "" }, editState: { label: "", value: "" } })} className="btn-icon btn-link like"><i className="tim-icons icon-simple-remove bg-danger" /> </Button>
                        </div>
                        :
                        <div>
                            <Button onClick={() => history.push({ pathname: '/print', state: data })} className="btn-icon btn-link like"> <i className="tim-icons icon-paper bg-warning" /> </Button>
                            <Button onClick={() => setMyStates({ editTip: { label: getTip(tip.type), value: tip.type }, editAmount: tip.amount, editPayment: { value: payment, label: payment }, editState: { label: state, value: state }, editId: _id })} className="btn-icon btn-link like"> <i className="tim-icons icon-pencil bg-success" /> </Button>
                        </div>
            }
        })

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Buscar ventas</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row >
                                <Col md={12}>
                                    <Label >Busqueda por cliente</Label>
                                    <Autosuggest
                                        suggestions={suggests}
                                        onSuggestionsFetchRequested={({ value }) => setSuggests(getSuggestions(value, clients))}
                                        onSuggestionsClearRequested={() => setSuggests([])}
                                        getSuggestionValue={({ name }) => name}
                                        renderSuggestion={({ name, phone, address, email }) => `${name}, +56(9) ${phone}, ${address}, ${email}`}
                                        onSuggestionSelected={(_, { suggestion: { _id } }) => getFilteredSells(_id)}
                                        inputProps={{
                                            placeholder: "Buscar cliente...",
                                            value: suggest,
                                            onChange: (_, { newValue }) => setSuggest(newValue),
                                            className: "form-control",
                                            disabled: loading
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col md={12}>
                                    <Label >Busqueda por Correlativo</Label>
                                    <Input disabled={loading} placeholder="165" onChange={(({ target: { value } }) => getByIndex(parseInt(value)))} />
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="2">Columnas a mostrar</Label>
                                <Col sm="12">
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDelivery" checked={stateDelivery} onChange={changeCheckBox} /><span className="form-check-sign" />Delivery</Label>                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateWrappers" checked={stateWrappers} onChange={changeCheckBox} /><span className="form-check-sign" />Rolls</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDate" checked={stateDate} onChange={changeCheckBox} /><span className="form-check-sign" />Fecha</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="statePayment" checked={statePayment} onChange={changeCheckBox} /><span className="form-check-sign" />Tipo pago</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateClient" checked={stateClient} onChange={changeCheckBox} /><span className="form-check-sign" />Cliente</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateUser" checked={stateUser} onChange={changeCheckBox} /><span className="form-check-sign" />Usuario</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateProducts" checked={stateProducts} onChange={changeCheckBox} /><span className="form-check-sign" />Productos</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="statePromotions" checked={statePromotions} onChange={changeCheckBox} /><span className="form-check-sign" />Promociones</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateNote" checked={stateNote} onChange={changeCheckBox} /><span className="form-check-sign" />Nota</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateOut" checked={stateOut} onChange={changeCheckBox} /><span className="form-check-sign" />Hora entrega</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateDiscount" checked={stateDiscount} onChange={changeCheckBox} /><span className="form-check-sign" />Descuento</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check><Input type="checkbox" name="stateTip" checked={stateTip} onChange={changeCheckBox} /><span className="form-check-sign" />Propina</Label>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <ReactTable
                                        loading={loading}
                                        data={getData()}
                                        filterable
                                        columns={getHeaders(states)}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        previousText='Anterior'
                                        nextText='Siguiente'
                                        loadingText='Cargando...'
                                        noDataText='No se encuentra la búsqueda'
                                        pageText='Página'
                                        ofText='de'
                                        rowsText='elementos'
                                    />
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history, match }) => (
    <SellProvider>
        <ClientProvider>
            <FormProvider initialValues={{ MyAlert: null, editAmount: 0, editId: "", editPayment: { label: "", value: "" }, editTip: { label: "", value: "" }, editState: { label: "", value: "" }, stateTip: true, stateDelivery: false, stateWrappers: false, stateDate: false, statePayment: true, stateClient: true, stateUser: false, stateProducts: false, statePromotions: false, stateDiscount: false }}>
                <Search history={history} match={match} />
            </FormProvider>
        </ClientProvider>
    </SellProvider>
)