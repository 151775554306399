// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import classNames from 'classnames'
// Components
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin";
import routes from "../../routes.js";
import { useCookies } from 'react-cookie';
import { $address, $logo } from '@Config'

export default props => {
  // Initial states
  const [{ activeColor, sidebarMini, opacity, sidebarOpened, backgroundColor }, setState] = useState({ activeColor: "blue", sidebarMini: true, opacity: 0, sidebarOpened: false, backgroundColor: "white-content" }),
    [notiRef, setNotiRef] = useState(null),
    [mainPanel, setMainPanel] = useState(null),
    notiCB = useCallback(n => setNotiRef(n), [setNotiRef]),
    mainPanelCB = useCallback(n => setMainPanel(n), [setMainPanel]),
    hasMount = useRef(false)
  const myState = { activeColor, sidebarMini, opacity, sidebarOpened, backgroundColor }
  const [{ userData }, ,] = useCookies(['userData']);
  // Setting component create and destroy
  useEffect(() => {
    document.title = $address()
    let ps = null;
    const showNavbarButton = () => {
      if (document.documentElement.scrollTop > 50 || document.scrollingElement.scrollTop > 50 || (mainPanel && mainPanel.scrollTop > 50))
        setState({ ...myState, opacity: 1 });
      else if (document.documentElement.scrollTop <= 50 || document.scrollingElement.scrollTop <= 50 || (mainPanel && mainPanel.scrollTop <= 50))
        setState({ ...myState, opacity: 0 });
    }
    if (navigator.platform.indexOf("Win") > -1 && mainPanel) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) ps = new PerfectScrollbar(tables[i]);

    }
    if (!hasMount.current) {
      window.addEventListener("scroll", showNavbarButton);
      hasMount.current = true
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (mainPanel) mainPanel.scrollTop = 0
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1 && ps && mainPanel) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      window.removeEventListener("scroll", showNavbarButton);
    }
  }, [setState, myState, mainPanel, setMainPanel, hasMount])
  // Functions
  const
    getRoutes = routes => {
      if (!userData) return <Redirect to="/auth/login" />
      if (!userData.type || !userData.token) return <Redirect to="/auth/login" />
      return routes.map(({ collapse, views, layout, path, component, type }, key) => {
        if (!type.includes(userData.type)) return null
        if (collapse) return getRoutes(views);
        if (layout === "/app") return <Route path={layout + path} component={component} key={key} />
        return null;
      });
    },
    getActiveRoute = routes => {
      let activeRoute = "Default Brand Text";
      for (let i = 0; i < routes.length; i++) {
        const { collapse, views, layout, path, name } = routes[i];
        if (collapse) {
          let collapseActiveRoute = getActiveRoute(views);
          if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
        } else
          if (window.location.href.indexOf(layout + path) !== -1) return name;
      }
      return activeRoute;
    },
    handleActiveClick = color => () => setState({ ...myState, activeColor: color }),
    handleMiniClick = () => {
      let notifyMessage = "Menú lateral pequeño ";
      if (document.body.classList.contains("sidebar-mini")) {
        setState({ ...myState, sidebarMini: false });
        notifyMessage += "desactivado...";
      } else {
        setState({ ...myState, sidebarMini: true });
        notifyMessage += "activado...";
      }
      notiRef.notificationAlert({ place: "tr", message: notifyMessage, type: "primary", icon: "tim-icons icon-bell-55", autoDismiss: 7 });
      document.body.classList.toggle("sidebar-mini");
    },
    toggleSidebar = () => {
      setState({ ...myState, sidebarOpened: !sidebarOpened });
      document.documentElement.classList.toggle("nav-open");
    },
    closeSidebar = () => {
      setState({ ...myState, sidebarOpened: false });
      document.documentElement.classList.remove("nav-open");
    },
    toggleColor = () => setState({ ...myState, backgroundColor: backgroundColor ? "" : "white-content" });
  // Rendering
  console.log($logo())
  return (
    <div className={classNames("wrapper", backgroundColor)}>
      <div className="rna-container">
        <NotificationAlert ref={notiCB} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity }}>
        <button className="minimize-sidebar btn btn-link btn-just-icon" onClick={handleMiniClick}>
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar {...props} routes={routes} activeColor={activeColor} logo={{ outterLink: "/", text: $address(), imgSrc: `/${$logo()}` }} closeSidebar={closeSidebar} />
      <div className="main-panel" ref={mainPanelCB} data={activeColor}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} brandText={getActiveRoute(routes)} sidebarOpened={sidebarOpened} toggleSidebar={toggleSidebar} />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/app/" to="/app/dashboard" />
        </Switch>
        {// we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (<Footer fluid />)}
      </div>
      <FixedPlugin activeColor={activeColor} sidebarMini={sidebarMini} handleActiveClick={handleActiveClick} handleMiniClick={handleMiniClick} toggleColor={toggleColor} />
    </div>
  )
}
