import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';

import Mutations from '@Graphql/client'
import { errorHandler, lookError } from '@Config/util'

export const ClientContext = createContext({
    addClient: async () => undefined,
    modifyClient: async () => undefined,
    removeClient: async () => undefined,
    clients: []
})

const ClientProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient(),
        [clients, setClients] = useState([]),
        [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            async function getClients() {
                try {
                    await resetStore()
                    const { data } = await query({ query: Mutations.GET_CLIENTS })
                    setClients(data.getClients)
                } catch (e) {
                    return lookError(e)
                }
            }
            getClients();
            setLoaded(true)
        }
    }, [setLoaded, loaded, query, setClients, resetStore])

    async function addClient({ name, phone, email, address }) {
        try {
            const { data: { addClient } } = await mutate({
                mutation: Mutations.ADD_CLIENT,
                variables: { name, phone: parseInt(phone), email, address }
            })
            setClients(clients.concat(addClient))
            return { ok: true, _id: addClient._id }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyClient({ name, phone, email, address, _id }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_CLIENT,
                variables: { name, phone: parseInt(phone), email, address, _id }
            })
            const index = clients.map(e => e._id).indexOf(_id)
            setClients(clients.slice(0, index).concat({ name, phone, email, address, _id }).concat(clients.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removeClient({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_CLIENT,
                variables: { _id }
            })
            const index = clients.map(e => e._id).indexOf(_id)
            setClients(clients.slice(0, index).concat(clients.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        addClient,
        clients,
        modifyClient,
        removeClient
    }

    return (
        <ClientContext.Provider value={context}>
            {children}
        </ClientContext.Provider>
    )
}

ClientProvider.propTypes = {
    children: element
}

export default ClientProvider