import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { numberToCLPFormater } from 'numbertoclpformater'
import moment from 'moment'
import 'moment/locale/es'

import { randomColor } from '@Config/util'
import { mixedConfig } from '@Config/charts.config'

export default ({ report, format }) => {

    const defaultColors = cb => {
        return {
            backgroundColor: cb(),
            borderColor: cb(),
            borderWidth: 1,
            hoverBackgroundColor: cb(),
            hoverBorderColor: cb(),
        }
    }

    const getLabels = () => {
        let labels = []
        moment.updateLocale('es', { week: { dow: 1, }, })
        for (let actual in report) {
            const date = moment(new Date(report[actual].date))
            switch (format) {
                case "Diario":
                    labels.push(`${date.format('dddd-DD-MMMM-YYYY')}`)
                    break;
                case "Semanal":
                    labels.push(`Semana: ${date.weeks() - date.add(0, 'month').startOf('month').weeks() + 1} ${date.format('MMMM-YYYY')}`)
                    break;
                case "Mensual":
                    labels.push(`${date.format('MMMM-YYYY')}`)
                    break;
                case "Anual":
                    labels.push(`Año ${date.format('YYYY')}`)
                    break;
                default:
                    break;
            }
        }
        return labels
    }

    const getDataset = () => {
        const
            total = { ...defaultColors(randomColor), label: "Venta total", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            transfer = { ...defaultColors(randomColor), label: "Venta en transferencias", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            debit = { ...defaultColors(randomColor), label: "Venta en débito", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            credit = { ...defaultColors(randomColor), label: "Venta en crédito", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            discount = { ...defaultColors(randomColor), label: "Total descuentos", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            cash = { ...defaultColors(randomColor), label: "Venta en efectivo", data: [], yAxisID: 'y-axis-1', type: 'bar' },
            ndiscount = { ...defaultColors(randomColor), label: "Total descuentos", data: [], yAxisID: 'y-axis-2', type: 'line', fill: false },
            ntransfer = { ...defaultColors(randomColor), label: "Total transferencias", data: [], yAxisID: 'y-axis-2', type: 'line', fill: false },
            ntransactions = { ...defaultColors(randomColor), label: "Total transacciones", data: [], yAxisID: 'y-axis-2', type: 'line', fill: false },
            tipTotal = { ...defaultColors(randomColor), label: "Propinas totales", data: [], yAxisID: 'y-axis-1', type: 'bar' };

        for (let actual in report) {
            total.data.push(parseInt(report[actual].total))
            transfer.data.push(parseInt(report[actual].transfer))
            debit.data.push(parseInt(report[actual].debit))
            credit.data.push(parseInt(report[actual].credit))
            discount.data.push(parseInt(report[actual].totalDiscount))
            cash.data.push(parseInt(report[actual].cash))
            ndiscount.data.push(parseInt(report[actual].discounts))
            ntransfer.data.push(parseInt(report[actual].detailTransfers.length))
            ntransactions.data.push(parseInt(report[actual].transactions))
            tipTotal.data.push(parseInt(report[actual].tip.cash) + parseInt(report[actual].tip.transfer) + parseInt(report[actual].tip.transbank) )
        }
        return [cash, debit, credit, transfer, discount, total, tipTotal, ndiscount, ntransfer, ntransactions]
    }


    const getData = () => ({ labels: getLabels(), datasets: getDataset() })

    const chart = {
        data: getData(),
        options: mixedConfig(numberToCLPFormater)
    }

    if (!report.length) return <Card>
        <CardHeader>
            <CardTitle tag="h4">No existen registros de ventas en tal rango de fechas</CardTitle>
        </CardHeader>
    </Card>
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">Reporte {format}</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <div className="chart-area">
                            <Bar
                                data={chart.data}
                                options={chart.options}
                            />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}