import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import BarcodeReader from 'react-barcode-reader';

import ScanGif from '../assets/img/scan.gif';

export default ({ showModal, setModal, onErrorScan, onScan }) => {
    return (
        <Modal isOpen={showModal} toggle={() => setModal(!showModal)}>
            <BarcodeReader onError={onErrorScan} onScan={onScan} />
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(!showModal)}>
                    <i className="tim-icons icon-simple-remove"></i>
                </button>
                <h5 className="modal-title">Escaneo de producto</h5>
            </div>
            <ModalBody className="d-flex flex-column align-center">
                <p>Escanee su producto porfavor.</p>
                <img src={ScanGif} alt="imageScan" />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setModal(!showModal)}> Cerrar </Button>
            </ModalFooter>
        </Modal >
    );
}
