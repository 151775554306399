import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { numberToCLPFormater } from 'numbertoclpformater'
import Select from "react-select";
import BarcodeReader from 'react-barcode-reader';
import { getCancelAlert, getSuccessAlert } from '@Config/util'


import ScanGif from '../../../assets/img/scan.gif';

const emptyProduct = { selectProduct: {}, amount: 0 }

class ModalScan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <Modal isOpen={this.props.showModal} toggle={() => this.props.setModal(!this.props.showModal)}>
                <BarcodeReader onError={this.props.onError} onScan={this.props.onScan} />
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.setModal(!this.props.showModal)}>
                        <i className="tim-icons icon-simple-remove"></i>
                    </button>
                    <h5 className="modal-title">Escaneo de producto</h5>
                </div>
                <ModalBody className="d-flex flex-column align-center">
                    <p>Escanee su producto porfavor.</p>
                    <img src={ScanGif} alt="imageScan" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.props.setModal(!this.props.showModal)}> Cerrar </Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            favorites: {},
            showModal: false,
        };
    }
    getName = id => {
        let prod = this.props.products.filter(p => id === p._id)[0]
        if (!prod) {
            prod = this.props.favorites.filter(p => id === p._id)[0]
        }
        return prod.name
    }
    isValidated = () => {
        let products = [], total = 0, totalProducts = []
        Object.keys(this.state.favorites).map(name => {
            if (this.state.favorites[name] && parseInt(this.state.favorites[name]) > 0) {
                products.push({ product: name, amount: parseInt(this.state.favorites[name]) })
            }
            return null
        })
        this.state.products.map(({ selectProduct: { value }, amount }) => {
            if (amount && value && parseInt(amount) > 0)
                products.push({ product: value, amount: parseInt(amount) })
            return null
        })
        for (let a in products) {
            if (products[a].amount) totalProducts.push({ name: this.getName(products[a].product), amount: products[a].amount })
            total += parseInt(products[a].amount * this.getPrice(products[a].product))
        }
        this.props.setInfo({ ...this.props.info, products: totalProducts, total4: total })
        this.props.setSell({ ...this.props.sell, products });
        return true
    }
    getPrice = id => {
        let prod = this.props.products.filter(p => id === p._id)[0]
        if (!prod) {
            prod = this.props.favorites.filter(p => id === p._id)[0]
        }
        return prod.price
    }
    removeProduct = nProduct => () => this.setState({ products: this.state.products.slice(0, nProduct).concat(this.state.products.slice(nProduct + 1)) })
    addProduct = () => this.setState({ products: this.state.products.concat(emptyProduct) })

    changeProduct = nProduct => selectProduct => {
        this.setState({
            products: this.state.products.slice(0, nProduct)
                .concat({ selectProduct, amount: 0 })
                .concat(this.state.products.slice(nProduct + 1))
        })
    }

    changeFavorite = _id => ({ target: { value } }) => this.setState({ favorites: { ...this.state.favorites, [_id]: value } })

    onErrorScan = (e) => {
        this.props.setMyStates({ MyAlert: getCancelAlert({ msg: 'Error', hideAlert: () => this.props.setMyStates({ MyAlert: null }) }) });
        console.log("error", e)
    }

    onScan = prod => {
        const searchedProduct = this.props.products.find(({ barcode }) => barcode === prod);
        if (searchedProduct) {
            const exist = this.state.products.findIndex(({ selectProduct: { value } }) => value === searchedProduct._id)
            const existFav = this.props.favorites.findIndex(({ _id }) => _id === searchedProduct._id)
            if (exist === -1 && existFav === -1)
                this.setState({
                    products: this.state.products.concat({
                        amount: 1,
                        selectProduct: {
                            label: searchedProduct.name,
                            value: searchedProduct._id,
                        }
                    }),
                    showModal: false
                })
            else {
                if (existFav === -1)
                    this.setState({
                        products: this.state.products.slice(0, exist).concat({
                            ...this.state.products[exist],
                            amount: this.state.products[exist].amount + 1,
                        }).concat(this.state.products.slice(exist + 1)),
                        showModal: false
                    })
                else {
                    const rescued = this.props.favorites[existFav];
                    this.setState({
                        favorites: {
                            ...this.state.favorites,
                            [rescued._id]: parseInt(this.state.favorites[rescued._id]) ? `${parseInt(this.state.favorites[rescued._id]) + 1}` : "1"
                        },
                        showModal: false
                    })
                }
            }
            this.props.setMyStates({ MyAlert: getSuccessAlert({ msg: `Producto añadido: ${searchedProduct.name}`, hideAlert: () => this.props.setMyStates({ MyAlert: null }) }) });
        } else {
            this.setState({ showModal: false })
            this.props.setMyStates({ MyAlert: getCancelAlert({ msg: 'Producto no encontrado.', hideAlert: () => this.props.setMyStates({ MyAlert: null }) }) });
        }
    }

    setModal = st => {
        this.setState({ showModal: st })
    }

    render() {

        const { products, favorites } = this.state
        return (
            <div className="content">
                <ModalScan showModal={this.state.showModal} setModal={this.setModal} onErrorScan={this.onErrorScan} onScan={this.onScan} />
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <Card>
                            <CardHeader><h3 className="title">¿Desea añadir algun producto?</h3></CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} className="d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0">Escanear producto</h4>
                                        <Button onClick={() => this.setModal(true)} className="btn-icon btn-link ml-2">
                                            <i className="tim-icons icon-camera-18 text-gray" />
                                        </Button>
                                    </Col>
                                    <Col md={12}><h5 className="info-text">Productos recomendados:</h5></Col>
                                    <Col md={12} className="mb-2">
                                        <Row className="mb-2">
                                            {
                                                this.props.favorites.map(({ name, price, _id }, n) =>
                                                (
                                                    <Col md={6} key={n} className="mb-2">
                                                        <Row>
                                                            <Col md={8}><Input value={`${name}` + (price ? `, ${numberToCLPFormater(price)}` : "")} disabled /></Col>
                                                            <Col md={4}><Input type="number" value={favorites[_id]} defaultValue={0} min="0" onChange={this.changeFavorite(_id)} /> </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                                )}
                                        </Row>
                                    </Col>
                                    {products.map((_, k) => (
                                        <Col md={12} key={k} className="mb-2">
                                            <Row>
                                                <Col md={6}>
                                                    <Select
                                                        className="react-select info mb-2"
                                                        classNamePrefix="react-select"
                                                        name={"select_product" + k}
                                                        value={products[k].selectProduct}
                                                        onChange={this.changeProduct(k)}
                                                        options={this.props.products.map(({ name, price, _id }) => ({ label: `${name}: ${numberToCLPFormater(price)}`, value: _id }))}
                                                        placeholder="Elija un producto"
                                                    />
                                                </Col>
                                                <Col md={5} className="d-flex flex-column" >
                                                    <Input type="number" min="0" value={products[k].amount} onChange={({ target: { value } }) => this.setState({ products: this.state.products.slice(0, k).concat({ ...this.state.products[k], amount: value }).concat(this.state.products.slice(k + 1)) })} />
                                                </Col>
                                                <Col md={1}>  <Button onClick={this.removeProduct(k)} className="btn-icon btn-link like"><i className="tim-icons icon-simple-remove bg-danger" /></Button></Col>
                                            </Row>
                                        </Col>
                                    ))}
                                    <Col className="my-3 text-center" md={12}> <Button onClick={this.addProduct}>Añadir</Button> </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}