import React from 'react'
import ReactExport from "react-export-excel";
import { Button } from "reactstrap";
import moment from 'moment'
import { numberToCLPFormater } from 'numbertoclpformater'
import { getFormat } from '@Config/util'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default ({ format, from, to, report }) => {
    const getDataRow = () => report.map(({ tip, date, cash, debit, credit, transfer, total, totalDiscount, discounts, transactions, detailTransfers }) => [moment(new Date(date)).format("DD-MMMM-YYYY"), numberToCLPFormater(cash), numberToCLPFormater(debit), numberToCLPFormater(credit), numberToCLPFormater(transfer), numberToCLPFormater(total), "", numberToCLPFormater(totalDiscount), discounts, transactions, numberToCLPFormater(tip.cash),numberToCLPFormater(tip.transfer), numberToCLPFormater(tip.transbank), numberToCLPFormater(parseInt(tip.cash) + parseInt(tip.transfer) + parseInt(tip.transbank)), detailTransfers.length, detailTransfers.join()])
    return <ExcelFile
        element={<Button>Descargar reporte</Button>}
        filename={`Reporte ${getFormat(format).label} desde el ${moment(new Date(from)).format('DD-MMMM-YYYY')} hasta el ${moment(new Date(to)).format('DD-MMMM-YYYY')}`}
    >
        <ExcelSheet dataSet={[
            {
                columns: ["Fecha", "Total efectivo", "Total débito", "Total crédito", "Total transferencias", "Total", "", "Total descuentos", "Cantidad de descuentos:", "Cantidad de transacciones","Propinas Efectivo" ,"Propinas Transferencias","Propinas Transbank","Propinas Totales","Cantidad de transferencias", "Correlativos de transferencias"], width: { wpx: 400 },
                data: getDataRow()
            }
        ]} name="Organization" />
    </ExcelFile>
}