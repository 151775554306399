import React from "react";
import classnames from "classnames";
import Autosuggest from 'react-autosuggest';

import { InputGroup, Label, InputGroupAddon, InputGroupText, Row, Col, Input } from "reactstrap";

import './style.css'
import { getCancelAlert } from '@Config/util'


const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
const cleanUp = val => val.toString().toLowerCase().trim()
const getSuggestions = (value, arr) => {
    const escapedValue = escapeRegexCharacters(cleanUp(value));
    if (escapedValue === '') { return []; }
    const regex = new RegExp('^' + escapedValue, 'i');
    return arr.filter(({ name, email, phone, address }) => (regex.test(cleanUp(name)) || regex.test(cleanUp(phone)) || regex.test(cleanUp(email)) || regex.test(cleanUp(address))));
}


export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            nameState: "",
            phone: "",
            phoneState: "",
            address: "",
            addressState: "",
            email: "",
            emailState: "has-success",
            suggests: [],
            suggest: "",
            _id: ""
        };
    }
    create = async () => {
        const { name, phone, email, address } = this.state
        const { _id, msg } = await this.props.addClient({ name, phone, email, address })
        this.props.setSell({ client: _id })
        if (msg)
            this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Error al crear cliente, " + msg }))
        return true
    }
    modify = async _id => {
        const { name, phone, email, address } = this.state
        const { msg } = await this.props.modifyClient({ name, phone, email, address, _id })
        this.props.setSell({ client: _id })
        if (msg)
            this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Error al modificar cliente, " + msg }))
        return true
    }
    isValidated = () => {
        const { name, phone, email, address } = this.state
        if (!(this.state.nameState === "has-success" && this.state.emailState === "has-success" && this.state.addressState === "has-success" && this.state.phoneState === "has-success")) {
            this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Ingrese los datos correctamente" }))
            return false
        }
        const exist = this.props.clients.filter(({ phone }) => this.state.phone.toString() === phone.toString())
        this.props.setInfo({ client: { name, phone, email, address } })
        if (this.state._id) {
            
            this.props.setSell({ client: this.state._id })
            return true
        }
        if (exist.length && !this.state._id) return this.modify(exist[0]._id)
        else return this.create()
    }

    validate = (value, name) => {
        switch (name) {
            case "name":
                return value.length >= 3 ? "has-success" : "has-danger"
            case "address":
                return value.length >= 3 ? "has-success" : "has-danger"
            case "phone":
                return value.toString().length === 8 ? "has-success" : "has-danger"
            case "email":
                return (value.includes('@') || !value.length) ? "has-success" : "has-danger"
            default:
                return "has-success"
        }
    }

    onChange = (_, { newValue }) => this.setState({ suggest: newValue })
    getSuggestionValue = ({ name }) => name
    renderSuggestion = ({ name, phone, address, email }) => `${name}, +56(9) ${phone}, ${address}, ${email}`
    onSuggestionsFetchRequested = ({ value }) => this.setState({ suggests: getSuggestions(value, this.props.clients) })
    onSuggestionsClearRequested = () => this.setState({ suggests: [] })
    onSuggestionSelected = (_, { suggestion: { name, address, email, phone, _id } }) => this.setState({ name, nameState: "has-success", address, addressState: "has-success", email, emailState: "has-success", phone, phoneState: "has-success", _id })
    changeForm = ({ target: { value, name } }) => this.setState({ [name]: value, [name + "State"]: this.validate(value, name), _id: "" })


    render() {
        const
            { name, nameState, phone, phoneState, address, addressState, email, emailState, suggests, suggest } = this.state

        return (
            <>
                <h5 className="info-text">Información de Cliente</h5>
                <Row >
                    <Col md={{ size: 6, offset: 3 }}>
                        <Label >Buscar cliente</Label>
                        <Autosuggest
                            suggestions={suggests}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            onSuggestionSelected={this.onSuggestionSelected}
                            inputProps={{ placeholder: "Buscar cliente...", value: suggest, onChange: this.onChange, className: "form-control" }}
                        />
                    </Col>
                </Row>
                <h5 className="info-text mt-4">Crear cliente nuevo</h5>
                <Row className="mt-3">
                    <Col md={{ size: 4, offset: 2 }}>
                        <InputGroup className={classnames(nameState)} size="lg">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="tim-icons icon-single-02" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="name"
                                value={name}
                                placeholder="Nombre cliente"
                                onChange={this.changeForm}
                            />
                            {nameState === "has-danger" ? (<label className="error">El nombre es requerido.</label>) : null}
                        </InputGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <InputGroup className={classnames(phoneState)} size="lg">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>+56 (9) </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="phone"
                                value={phone}
                                placeholder="Telefono"
                                onChange={this.changeForm}
                            />
                            {phoneState === "has-danger" ? (<label className="error">El telefono es requerido y debe ser de 8 dígitos.</label>) : null}
                        </InputGroup>
                    </Col>
                    <Col md={{ size: 4, offset: 2 }}>
                        <InputGroup className={classnames(emailState)} size="lg">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>@</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="email"
                                value={email}
                                placeholder="Correo"
                                onChange={this.changeForm}
                            />
                            {emailState === "has-danger" ? (<label className="error">El correo no es válido.</label>) : null}
                        </InputGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <InputGroup className={classnames(addressState)} size="lg">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="tim-icons icon-bank" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="address"
                                value={address}
                                placeholder="Dirección"
                                onChange={this.changeForm}
                            />
                            {addressState === "has-danger" ? (<label className="error">La dirección es requerida.</label>) : null}
                        </InputGroup>
                    </Col>
                </Row>
            </>
        );
    }
}