import React from 'react'
import ReactBSAlert from "react-bootstrap-sweetalert";

export const getNumber = number => { const RegExpID = new RegExp("^[0-9]*$"), temp = number.toString(); let parsed = ""; for (let i = 0; i < temp.length; i++) if (RegExpID.test(temp[i])) parsed += temp[i]; return parseInt(parsed ? parsed : "0"); }
export const isWeight = str => { try { let temp = str.toLowerCase(); if (!temp.includes(':') || !temp.includes(',')) return false; temp = temp.replace(',', '').split(':'); if (temp[1].includes('gr') || temp[1].includes('un') || temp[1].includes('cc')) { const unity = temp[1].includes('gr') ? "gr" : (temp[1].includes('cc') ? "cc" : "un"); const amount = parseInt(getNumber(temp[1].split(unity)[0].trim())); return { name: temp[0].trim(), unity, amount } } return false; } catch (e) { return false } }
export const getIndexFromArray = (array, obj, indexes) => { return array.map(o => indexes.map(name => o[name] === obj[name]).includes(false) ? false : true).indexOf(true) }
export const getCancelAlert = ({ hideAlert, msg = "" }) => <ReactBSAlert danger style={{ display: "block" }} title="Operación cancelada." onConfirm={hideAlert} onCancel={hideAlert} confirmBtnBsStyle="success" >{msg.toString()}</ReactBSAlert>
export const getSuccessAlert = ({ hideAlert, msg = "" }) => <ReactBSAlert success style={{ display: "block" }} title={msg} onConfirm={hideAlert} onCancel={hideAlert} confirmBtnBsStyle="success" />
export const getWarningAlert = ({ title, confirm, cancel, body }) => <ReactBSAlert warning style={{ display: "block" }} title={title} onConfirm={confirm} onCancel={cancel} confirmBtnBsStyle="success" cancelBtnBsStyle="danger" confirmBtnText="Si" cancelBtnText="Cancelar" showCancel >{body}</ReactBSAlert>
export const infoBody = ({ name, price }) => <>Nombre: <b>{name}</b><br />Precio: <b>{price}</b></>
export const infoBodyClient = ({ name }) => <>Nombre: <b>{name}</b></>
export const getArrayOfWeight = str => str.split(',').map(a => { if (a.includes(':')) { a.replace('gr').replace('GR'); return { name: a.split(':')[0].trim(), amount: parseInt(a.split(':')[1].trim()) } } return null }).filter(a => a)
export const errorHandler = e => { if (e.toString().includes('Error: GraphQL error: ')) return { msg: e.toString().replace('Error: GraphQL error: ', '') }; if (e.toString().includes('Error: Network error: ')) { console.log(e.networkError ? (e.networkError.result ? (e.networkError.result.errors ? e.networkError.result.errors : "Network error") : "Network error") : "Network error"); return { msg: e.networkError ? (e.networkError.result ? (e.networkError.result.errors ? e.networkError.result.errors : "Network error") : "Network error") : "Network error" }; } console.log(e); return { msg: "Error" }; }
export const lookError = e => { if (e.toString().includes('Error: GraphQL error: ')) return console.log(e.toString().replace('Error: GraphQL error: ', '')); if (e.toString().includes('Error: Network error: ')) return console.log(e.networkError ? (e.networkError.result ? (e.networkError.result.errors ? e.networkError.result.errors : "Network error") : "Network error") : "Network error"); return console.log("Error"); }
export const getField = ({ array, field, id }) => { const data = array.filter(p => id === p._id)[0]; if (data) return data[field]; return []; }
export const randomColor = () => '#' + ("000000" + Math.random().toString(16).slice(2, 8).toUpperCase()).slice(-6)
export const getFormat = format => { switch (format) { case "daily": return { value: "daily", label: "Diario" }; case "weekly": return { value: "weekly", label: "Semanal" }; case "monthly": return { value: "monthly", label: "Mensual" }; case "yearly": return { value: "yearly", label: "Anual" }; default: return { value: "daily", label: "Diario" }; } }
export const getTip = type => type === "cash" ? "Efectivo" : (type === "transbank" ? "Transbank" : "Transferencia")