import React, { useContext, useEffect, useState } from 'react'
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Button, FormGroup } from "reactstrap";
import moment from 'moment'
import 'moment/locale/es'

import SellProvider, { SellContext } from '@Contexts/sell'
import FormProvider, { FormContext } from '@Contexts/form'
import ReportComponent from './ReportComponent'
import ReportExcel from './ReportExcel'
import { getFormat } from '@Config/util'



const Reports = ({ history, match: { params } }) => {
    const
        { values: { MyAlert, from, to, format }, setMyStates } = useContext(FormContext),
        { report, getReport, loading } = useContext(SellContext),
        [ready, setReady] = useState(false),
        [type, setType] = useState("Diario")

    useEffect(() => {
        async function start() {
            setReady(true)
            await getReport({ from: new Date(params.from), to: new Date(params.to), format: params.format })
            setType(format.label)
        }
        if (params.from && params.to && params.format) {
            const from = moment(new Date(params.from)), to = moment(new Date(params.to))
            if (!from.isValid() || !to.isValid()) history.replace('/app/reports')
            else if (!ready) start()
        }
    }, [params, getReport, history, ready, format])

    const setData = x => y => setMyStates({ [x]: y }),
        valid = x => y => { if (x === "from") return y.isBefore(to); else return y.isAfter(from); }

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={{ size: 8, offset: 2 }}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Generar reportes</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">Desde</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup>
                                                <ReactDatetime
                                                    inputProps={{
                                                        className: "form-control"
                                                    }}
                                                    timeFormat={false}
                                                    onChange={setData("from")}
                                                    value={from}
                                                    isValidDate={valid("from")}
                                                />
                                            </FormGroup>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">Hasta</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup>
                                                <ReactDatetime
                                                    inputProps={{
                                                        className: "form-control"
                                                    }}
                                                    timeFormat={false}
                                                    onChange={setData("to")}
                                                    value={to}
                                                    isValidDate={valid("to")}
                                                />
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">Desde</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup>
                                                <Select
                                                    className="react-select info"
                                                    classNamePrefix="react-select"
                                                    name="format"
                                                    options={[
                                                        { value: "daily", label: "Diario" },
                                                        { value: "weekly", label: "Semanal" },
                                                        { value: "monthly", label: "Mensual" },
                                                        { value: "yearly", label: "Anual" }
                                                    ]}
                                                    onChange={setData("format")}
                                                    value={format}
                                                    placeholder="Formato"
                                                />

                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-between">
                                    <Button disabled={loading} onClick={() => { setReady(false); history.replace(`/app/reports/${format.value}/${from._d}/${to._d}`); }} >Generar informe</Button>
                                    {(ready && !loading) ? <ReportExcel report={report} from={params.from} to={params.to} format={params.format} /> : null}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                {(ready && !loading) ?
                    <Col md={{ size: 10, offset: 1 }}>
                        <ReportComponent report={report} format={type} />
                    </Col> : null
                }
            </Row>
        </div>
    )
}

export default ({ history, match }) => (
    <SellProvider>
        <FormProvider initialValues={{ MyAlert: null, from: match.params.from ? moment(new Date(match.params.from)) : moment(), to: match.params.to ? moment(new Date(match.params.to)) : moment(), format: getFormat(match.params.format) }}>
            <Reports history={history} match={match} />
        </FormProvider>
    </SellProvider>
)