import React, { useContext } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Input } from "reactstrap";
import { useCookies } from 'react-cookie';
import ClientProvider, { ClientContext } from '@Contexts/client'
import FormProvider, { FormContext } from '@Contexts/form'
import { getNumber, getCancelAlert, getSuccessAlert, getWarningAlert, infoBodyClient } from '@Config/util'

const Clients = () => {
    const { clients, modifyClient, removeClient, addClient } = useContext(ClientContext),
        { values: { editId, editName, editPhone, editEmail, editAddress, createName, createPhone, createEmail, createAddress, createNew, MyAlert }, handleInputChange, setMyStates } = useContext(FormContext)
    
    const [{ userData }, ,] = useCookies(['userData']);
    const
        remove = ({ id, name, phone }) => () => setMyStates({
             MyAlert: getWarningAlert({ 
             title: "¿Está seguro de eliminar el cliente?", 
             confirm: () => confirmRemove(id),
             cancel: () => setMyStates({ MyAlert: getCancelAlert({hideAlert}) }), 
             body: infoBodyClient({ name, phone: getNumber(phone) }) }) }),
        confirmRemove = async _id => {
            const { ok, msg } = await removeClient({ _id })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La envoltura ha sido eliminada!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
        },
        modify = (id, name, phone, email, address) => () => setMyStates({ editId: id, editName: name, editPhone: getNumber(phone), editEmail: email, editAddress: address }),
        confirmModify = async () => {
            await modifyClient({ name: editName, phone: getNumber(editPhone), email: editEmail, address: editAddress, _id: editId })
            setMyStates({ editId: "", editName: "", editPhone: "", editEmail: "", editAddress: "" })
        },
        
        cancelModify = () => setMyStates({ editId: "", editName: "", editPhone: "", editEmail: "", editAddress: "" }),
        hideAlert = () => setMyStates({ MyAlert: null }),
        create = () => { },
        beforChange = ({ target: { value, name } }) => handleInputChange({ target: { value: getNumber(value), name } }),
        createClient = () => setMyStates({ createName: "", createPhone: "", createEmail: "", createAddress: "",createNew: true }),
        confirmCreate = async () => {
            await addClient({ name: createName, phone: getNumber(createPhone), email: createEmail, address: createAddress })
            cancelCreate()
        },
        cancelCreate = () => setMyStates({  createName: "", createPhone: "", createEmail: "", createAddress: "", createNew: false }),
        getData = () => {
            return clients.map(({ name, phone, email, address, _id }) => {
                return {
                    id: _id,
                    name: (editId === _id) ? <Input className="text-right" name="editName" placeholder="Nombre" onChange={handleInputChange} value={editName} /> : name,
                    phone: (editId === _id) ? <Input className="text-right" name="editPhone" placeholder="Teléfono" onChange={beforChange} value={editPhone} /> : "+56(9)"+phone,
                    email: (editId === _id) ? <Input className="text-right" name="editEmail" type="email" placeholder="contacto@example.com" onChange={handleInputChange} value={editEmail} /> : email,
                    address: (editId === _id) ? <Input className="text-right" name="editAddress" placeholder="Av. Lomas San Sebastian 1035" onChange={handleInputChange} value={editAddress} /> : address,
                    actions:
                        (editId === _id) ?
                            <div className="actions-right">
                                <Button onClick={confirmModify} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-check-2 bg-success" />
                                </Button>
                                <Button onClick={cancelModify} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                            :
                            <div className="actions-right">
                                <Button onClick={modify(_id, name, phone, email, address)} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                                <Button onClick={remove({ name, phone, email, address, id: _id })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                };
            })
        },
        getColumns = () => {
           let columns = [
                {
                    
                    Header: "Nombre",
                    accessor: "name",                                        
                    className: "text-center",
                    Footer: createNew ? <Input name="createName" value={createName} placeholder="Nombre" onChange={handleInputChange} required/> : null
                },
                {
                    Header: "Teléfono",
                    accessor: "phone",
                    className: "mx-2 text-right",
                    Footer: createNew ? <Input name="createPhone" className="text-right" type="phone" value={createPhone} placeholder="Teléfono" onChange={beforChange} required/> : null

                },
                {
                    Header: "Correo",
                    accessor: "email",
                    className: "text-center",
                    Footer: createNew ? <Input name="createEmail" value={createEmail} type="email" placeholder="contacto@ejemplo.com" onChange={handleInputChange} required /> : null

                },
                {
                    Header: "Dirección",
                    accessor: "address",
                    className: "text-center",
                    Footer: createNew ? <Input name="createAddress" value={createAddress} placeholder="Colo colo 1372" onChange={handleInputChange} required/> : null

                }]
            
            if (userData.type === "administrador")
                columns.push({
                    Header: "Acciones",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Footer:
                        <Row>
                            {createNew ?
                                <>
                                    <Col md={6}>
                                        <Button size="sm" color="success" onClick={confirmCreate}>Guardar</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button size="sm" color="danger" onClick={cancelCreate}>Cancelar</Button>
                                    </Col>
                                </>
                                :
                                <Col md={6} >
                                    <Button size="sm" onClick={createClient}>Crear nuevo</Button>
                                </Col>
                            }
                        </Row>
                })
                return columns;
            }
    


    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de clientes</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColumns()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                                Footer={<div style={{ width: '100%', textAlign: 'right' }}><Button onClick={create}>Crear</Button></div>}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (
    <ClientProvider>
        <FormProvider initialValues={{ editId: "", editName: "", editPhone: "", editEmail: "", editAddress:"", createName: "", createPhone: "", createEmail: "", createAddress: "", createNew: false, MyAlert: null }}>
            <Clients history={history} />
        </FormProvider>
    </ClientProvider>
)