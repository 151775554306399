import React, { createContext, useState } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';
import { useCookies } from 'react-cookie';

import Mutations from '@Graphql/sell'
import { errorHandler } from '@Config/util'

const
    defaultDailySells = { tip: { cash: 0, transfer: 0, transbank: 0 }, weight: [{ name: "", unity: "", weight: 0 }], total: 0, transactions: 0, discounts: 0, totalDiscount: 0, transfer: 0, debit: 0, credit: 0, cash: 0, rappi: 0, check: 0 },
    defaultBalance = { tip: { cash: 0, transfer: 0, transbank: 0 }, discounts: 0, totalDiscount: 0, debit: 0, cash: 0, credit: 0, rappi: 0, check: 0, transfer: 0, total: 0 }

export const SellContext = createContext({
    addSell: async () => undefined,
    getReportFromDay: async () => undefined,
    modifySell: async () => undefined,
    getSells: async () => undefined,
    getCashierBalancing: async () => undefined,
    getReport: async () => undefined,
    getFilteredSells: async () => undefined,
    getByIndex: async () => undefined,
    cashierBalancing: defaultBalance,
    sell: {},
    report: [],
    dailyReport: { dailySells: defaultDailySells, lastMonths: [] },
    sells: [],
    sellsFiltered: [],
    loading: false
})

const SellProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient({}),
        [sell, setSell] = useState({}),
        [dailyReport, setDailyReport] = useState({ dailySells: defaultDailySells, lastMonths: [] }),
        [report, setReport] = useState([]),
        [sells, setSells] = useState([]),
        [sellsFiltered, setSellsFiltered] = useState([]),
        [{ userData }, ,] = useCookies(['userData']),
        [loading, setLoading] = useState(false),
        [cashierBalancing, setCashierBalancing] = useState(defaultBalance)

    async function getCashierBalancing() {
        try {
            setLoading(true)
            await resetStore()
            const { data: { getSellsInRange } } = await query({ query: Mutations.GET_SELLS_RANGE_BALANCING, variables: { from: new Date(), to: new Date(), format: 'daily' } })
            setCashierBalancing(getSellsInRange.length ? getSellsInRange[0] : defaultBalance)
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function getReportFromDay() {
        try {
            setLoading(true)
            await resetStore()
            const { data: { getSellsInRange } } = await query({ query: Mutations.GET_SELLS_RANGE_DASHBOARD, variables: { from: new Date(), to: new Date(), format: 'daily' } })
            const { data: { getLastMonthsSells = [] } } = await query({ query: Mutations.GET_LAST_MONTHS })
            setDailyReport({
                dailySells: (Array.isArray(getSellsInRange) && getSellsInRange.length) ? getSellsInRange[0] : defaultDailySells,
                lastMonths: getLastMonthsSells
            })
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }
    async function getSells(page) {
        try {
            if ((sells.length + 5 > page * 5)) return;
            setLoading(true)
            await resetStore()
            const sorted = sells.sort((a, b) => a.index - b.index);
            const { data: { getSellPagination } } = await query({ query: Mutations.GET_SELLS_PAGINATION, variables: { last: !sorted.length ? 0 : sorted[0].index, limit: !sells.length ? page * 5 : 5 } })
            setSells(sells.concat(getSellPagination).sort((a, b) => b.index - a.index))
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function getReport({ from, to, format }) {
        try {
            setLoading(true)
            await resetStore()
            const { data: { getSellsInRange } } = await query({ query: Mutations.GET_SELLS_RANGE_REPORT, variables: { from, to, format } })
            setReport(getSellsInRange)
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function addSell(data) {
        try {
            const { data: { addSell } } = await mutate({
                mutation: Mutations.ADD_SELL,
                variables: { ...data, user: userData._id }
            })
            return { ok: true, state: addSell }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifySell(data) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_SELL,
                variables: data
            })
            const index = sells.map(e => e._id).indexOf(data._id)
            if (index >= 0)
                setSells(sells.slice(0, index).concat({ ...sells[index], state: data.state, tip: data.tip, payment: data.payment }).concat(sells.slice(index + 1)))
            const index2 = sellsFiltered.map(e => e._id).indexOf(data._id)
            if (index2 >= 0)
                setSellsFiltered(sellsFiltered.slice(0, index2).concat({ ...sellsFiltered[index2], state: data.state, tip: data.tip, payment: data.payment }).concat(sellsFiltered.slice(index2 + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function getFilteredSells(_id) {
        try {
            setLoading(true)
            await resetStore()
            const { data: { getSellsFiltered } } = await query({ query: Mutations.GET_SELLS_FILTERED, variables: { _id } })
            setSellsFiltered(getSellsFiltered)
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function getByIndex(index) {
        try {
            if (typeof index !== "number" && index < 1) return
            setLoading(true)
            await resetStore()
            const { data: { getSellsFilteredIndex } } = await query({ query: Mutations.GET_SELLS_FILTERED_INDEX, variables: { index } })
            setSellsFiltered(getSellsFilteredIndex)
            setLoading(false)
        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        addSell,
        sells,
        sell,
        dailyReport,
        setSell,
        modifySell,
        getReportFromDay,
        getSells,
        getCashierBalancing,
        cashierBalancing,
        loading,
        report,
        getReport,
        sellsFiltered,
        getFilteredSells,
        getByIndex
    }

    return (
        <SellContext.Provider value={context}>
            {children}
        </SellContext.Provider>
    )
}

SellProvider.propTypes = {
    children: element
}

export default SellProvider