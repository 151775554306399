import React, { useEffect, useState } from 'react'
import { Button } from "reactstrap";

import moment from 'moment'

import { getTip } from '@Config/util'

function clp(input) {
    let stringValue = `${input}`;
    stringValue = stringValue.replace(/[\$\.]/g, '');
    const numberValue = parseInt(stringValue, 10);
    if (isNaN(numberValue)) {
        console.log(`error al transformar ${input} - ${stringValue} - ${numberValue}`);
        return '0';
    }
    stringValue = numberValue.toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
    });
    return stringValue;
}

export default (props) => {
    const [{ tip, discount, client, user, wrappers, total, products, promotions, createdAt, payment, index, delivery, out, note }, setState] = useState({ client: {}, user: {}, wrappers: [], total: 0, products: [], promotions: [], createdAt: "", payment: "", index: 0, delivery: false, out: new Date(), note: "", tip: {} }),
        [first, setFirst] = useState(true)
    const isDesk = navigator.platform.includes("Win") || navigator.platform.includes("Mac");

    function printRecipe() {
        var content = document.getElementById("toPrint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    useEffect(() => {
        if (first) {
            if (!props.location) return props.history.push('/app/dashboard')
            if (!props.location.state) return props.history.push('/app/dashboard')
            const { location: { state: { tip = {}, discount = 0, client = {}, user = {}, wrappers = [], total = 0, products = [], promotions = [], createdAt = "", payment = "", index = 0, delivery = false, out = new Date(), note = "" } } } = props
            setState({ tip, discount, client, user, wrappers, total, products, promotions, createdAt, payment, index, delivery, out, note })
            setFirst(false)
        } else {
            setTimeout(printRecipe, 2000)
            setTimeout(printRecipe, 5000)
        }
    }, [setState, props, first, setFirst])

    return (
        <>
            <div className="toPrint" id="toPrint" style={{ backgroundColor: 'white', width: '80mm' }}>
                <style>
                    {`@media print { body, html, .toPrint { position: absolute;margin:0;padding:10px;border:0;font-size:18px;height:100%;width:80mm;background-color: green;color: black; text-transform:uppercase; padding-bottom:100px;} }`}
                </style>
                <div><b>Correlativo: {index}</b></div>
                <div>Cajero/a: {user.name}</div>
                <div>Fecha: {isDesk ? moment(createdAt).format('DD/MM/YYYY HH:mm') : moment(createdAt).subtract(1, 'hours').format('DD/MM/YYYY HH:mm')}</div>

                <div>Telefono: {client.phone}</div>
                <div>Nombre: {client.name}</div>
                <div>Direccion:   {client.address}</div>
                {delivery ? <div><b>PARA DESPACHO A DOMICILIO</b></div> : null}
                <div>Entrega: <b>{isDesk ? moment(out).format('DD/MM/YYYY HH:mm') : moment(out).subtract(1, 'hours').format('DD/MM/YYYY HH:mm')}</b> </div>
                {note ? <div><b>NOTA: {note}</b></div> : null}
                <div>-----------------------</div>
                {wrappers.length > 0 ? <div><b>ROLLS UNITARIOS</b></div> : null}
                {wrappers.map(({ wrapper = {}, ingredients = [] }, k) => <><div key={k}>* {wrapper.alias.toUpperCase()}--{ingredients.map(({ alias = "" }) => alias.toUpperCase() + ",")}</div><div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(wrapper.price)}]</b></div></>)}
                {promotions.length > 0 ? <div><b>ROLLS PROMOCIONES</b></div> : null}
                {promotions.map(({ ingredients = [], promotion = {} }) => {
                    let toRet = []
                    toRet.push(<div><b>* {promotion.name}</b></div>)
                    if (ingredients.length)
                        ingredients.map(({ wrapper = {}, ingredients = [] }, k) => { toRet.push(<div key={k}>{wrapper.alias}--{ingredients.map(({ alias = "" }) => alias.toUpperCase() + ",")} </div>); return null })
                    else
                        promotion.wrappers.map(({ wrapper: { alias = "" } }, k) => { toRet.push(<div key={k}>{alias.toUpperCase() + "--ELECCION DEL CHEF"}</div>); return null })
                    toRet.push(<div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(promotion.price)}]</b></div>)
                    toRet.push(<div>-----------------------</div>)
                    return toRet
                })}

                {
                    (products.length > 0 || promotions.map(({ promotion: { products } }) => products.length ? true : false).includes(true))
                        ? <div><b>PRODUCTOS</b></div>
                        : null
                }

                {products.map(({ product, amount }, k) => {
                    return <><div key={k}>[{amount}]{product.name.toUpperCase()}</div><div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(product.price)}] C/U</b></div></>
                })}

                {
                    promotions.map(({ promotion: { products } }) => {
                        console.log(products);
                        return products.map(({ product }, k) => {
                            return (<div key={k}>{product.name?.toUpperCase()}</div>)
                        })
                    })
                }

                <div>-----------------------</div>
                <div><b>Total Comanda:</b></div>
                <div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(total)}]</b></div>
                <div>Descuento a aplicar: {discount}%</div>
                <div>Precio final:</div>
                <div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(total * (100 - discount) / 100)}]</b></div>
                <div>Propina:</div>
                <div style={{ textAlign: 'right', paddingRight: 2 }}><b>[ {clp(tip.amount)}, {getTip(tip.type)}]</b></div>
                <div><b>Total + Propina: </b></div>
                <div style={{ textAlign: 'right', paddingRight: 2 }}><b>[{clp(tip.amount + (total * (100 - discount) / 100))}]</b></div>
                <div>Cancela con: {payment}</div>
                <div>Entrega: {isDesk ? moment(out).format('DD/MM/YYYY HH:mm') : moment(out).subtract(1, 'hours').format('DD/MM/YYYY HH:mm')}</div>
            </div>
            <div>
                <Button type="button" onClick={printRecipe}>Volver a imprimir</Button>
            </div>
            <iframe id="ifmcontentstoprint" title="printer" style={{ height: 0, width: 0, position: 'absolute' }}></iframe>
        </>
    )
}
