import React, { useContext, useEffect, useState } from 'react'
import ReactTable from "react-table";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";
import { numberToCLPFormater } from 'numbertoclpformater'

import CardSell from '@Components/CardSell'
import SellProvider, { SellContext } from '@Contexts/sell'
import { lineConfig, lineConfigDataset, setCanvas, pieConfig, pieConfigDataset } from '@Config/charts.config'
import { weightTableConfig } from '@Config/table.config'
import CashierBalancing from '@Components/CashierBalancing'
import { useCookies } from 'react-cookie';

const Dashboard = () => {
    const
        { dailyReport, getReportFromDay, loading, getCashierBalancing, cashierBalancing } = useContext(SellContext),
        [ready, setReady] = useState(false),
        [{ userData }, ,] = useCookies(['userData']);

    useEffect(() => {
        async function start() {
            setReady(true)
            await getReportFromDay();
            await getCashierBalancing();
        }
        if (!ready) start()
    }, [getReportFromDay, ready, getCashierBalancing])

    const lineChart = {
        data: canvas => {
            let gradientStroke = canvas.getContext("2d").createLinearGradient(0, 230, 0, 50)
            setCanvas(gradientStroke)
            return {
                labels: dailyReport.lastMonths.map(({ month }) => month),
                datasets: [
                    {
                        ...lineConfigDataset(gradientStroke),
                        data: dailyReport.lastMonths.map(({ total }) => total)
                    }
                ]
            };
        },
        options: {
            ...lineConfig,
            tooltips: {
                callbacks: {
                    label: ({ yLabel }) => "Total: " + numberToCLPFormater(yLabel)
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: value => numberToCLPFormater(parseInt(value))
                    }
                }]
            }
        }
    };

    const pieChart = {
        data: () => {
            return {
                labels: dailyReport.dailySells.weight.map(({ name, unity }) => `${name} ${unity}`),
                datasets: [
                    {
                        ...pieConfigDataset,
                        data: dailyReport.dailySells.weight.map(({ weight }) => weight)
                    }
                ]
            }
        },
        options: pieConfig
    }
    return (
        <div className="content">
            <Row>
                <Col md={12} className="mb-3">
                    <CashierBalancing
                        cashierBalancing={cashierBalancing}
                        loading={loading}
                    />
                </Col>
                {userData.type === "administrador" ?
                    <Col md="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Total Ventas Mensuales IDASHI. </h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-bell-55 text-info" />{" "}

                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line data={lineChart.data} options={lineChart.options} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col> : null}
                <Col xs={12} md={12}>
                    <Row>
                        <CardSell title="Ventas mediante Efectivo" value={dailyReport.dailySells.cash} />
                        <CardSell title="Ventas mediante Débito" value={dailyReport.dailySells.debit} />
                        <CardSell title="Ventas mediante Crédito" value={dailyReport.dailySells.credit} />
                        <CardSell title="Ventas mediante Transferencia" value={dailyReport.dailySells.transfer} />
                        <CardSell title="Ventas mediante Cheques" value={dailyReport.dailySells.check} />
                        <CardSell title="Ventas mediante Rappi" value={dailyReport.dailySells.rappi} />
                        <CardSell title="Ventas Totales" value={dailyReport.dailySells.total} />
                        <CardSell title="Cantidad de ventas" value={dailyReport.dailySells.transactions} offMoney={true} />
                        <CardSell title="Promedio de ventas" value={dailyReport.dailySells.transactions ? parseInt(dailyReport.dailySells.total / dailyReport.dailySells.transactions) : 0} />
                        <CardSell title="Cantidad de descuentos" value={dailyReport.dailySells.discounts} />
                        <CardSell title="Suma de descuentos aplicados" value={dailyReport.dailySells.totalDiscount} />
                        <CardSell title="Propinas transbank" value={dailyReport.dailySells?.tip?.transbank} />
                        <CardSell title="Propinas transferencia" value={dailyReport.dailySells?.tip?.transfer} />
                        <CardSell title="Propinas efectivo" value={dailyReport.dailySells?.tip?.cash} />
                        <CardSell title="Propinas totales" value={(parseInt(dailyReport.dailySells.tip?.transbank) + parseInt(dailyReport.dailySells.tip?.cash) + parseInt(dailyReport.dailySells.tip?.transfer))} />

                    </Row>
                </Col>


                <Col xs={12} md={12}>
                    <Row>

                        <Col xs="12" md="6">
                            <Card className="card-chart-pie">
                                <CardHeader>
                                    <h5 className="card-category">Total Materias Primas Diario IDASHI. </h5>
                                    <CardTitle tag="h3">
                                        <i className="tim-icons icon-bell-55 text-info" />{" "}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <div className="chart-area">
                                                <Pie
                                                    data={pieChart.data}
                                                    options={pieChart.options}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={6}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Tabla de Total Materias Primas</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dailyReport.dailySells.weight}
                                        {...weightTableConfig}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (
    <SellProvider>
        <Dashboard history={history} />
    </SellProvider>
)