import React, { useContext } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Input, Badge } from "reactstrap";
import { numberToCLPFormater } from 'numbertoclpformater'
import { useCookies } from 'react-cookie';

import WrapperProvider, { WrapperContext } from '@Contexts/wrapper'
import FormProvider, { FormContext } from '@Contexts/form'
import { getNumber, getCancelAlert, getSuccessAlert, getWarningAlert, infoBody, isWeight, getIndexFromArray } from '@Config/util'

const Wrappers = () => {
    const { wrappers, modifyWrapper, removeWrapper, addWrapper } = useContext(WrapperContext),
        { values: { editAlias, editId, editName, editPrice, editIngredients, editWeight, editWeights, createName, createPrice, createIngredients, createWeight, createWeights, createNew, createAlias, MyAlert }, handleInputChange, setMyStates } = useContext(FormContext)
    const [{ userData }, ,] = useCookies(['userData']);
    const
        remove = ({ id, name, price }) => () => setMyStates({
            MyAlert: getWarningAlert({
                title: "¿Está seguro de eliminar la envoltura?",
                confirm: () => confirmRemove(id),
                cancel: () => setMyStates({ MyAlert: getCancelAlert({ hideAlert }) }),
                body: infoBody({ name, price: numberToCLPFormater(getNumber(price)) })
            })
        }),
        confirmRemove = async _id => {
            const { ok, msg } = await removeWrapper({ _id })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La envoltura ha sido eliminada!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
        },
        modify = (id, name, price, weight, ingredients, alias) => () => setMyStates({ editId: id, editName: name, editPrice: numberToCLPFormater(getNumber(price)), editWeight: '', editWeights: weight, editIngredients: ingredients, editAlias: alias }),
        confirmModify = async () => {
            const { ok, msg } = await modifyWrapper({ name: editName, price: getNumber(editPrice), _id: editId, weight: editWeights, ingredients: editIngredients, alias: editAlias })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La envoltura ha sido modificada!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) setMyStates({ editId: "", editName: "", editPrice: "", editIngredients: 1, editWeight: "", editWeights: [], editAlias: "" })
        },
        cancelModify = () => setMyStates({ editId: "", editName: "", editPrice: "", editIngredients: 1, editWeight: "", editWeights: [], editAlias: "" }),
        hideAlert = () => setMyStates({ MyAlert: null }),
        beforChange = ({ target: { value, name } }) => handleInputChange({ target: { value: numberToCLPFormater(getNumber(value)), name } }),
        createWrapper = () => setMyStates({ createName: "", createPrice: "", createWeight: "", createWeights: [], createNew: true, createAlias: "" }),
        confirmCreate = async () => {
            const { ok, msg } = await addWrapper({ name: createName, price: getNumber(createPrice), weight: createWeights, ingredients: createIngredients, alias: createAlias })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La envoltura ha sido creada!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) cancelCreate()
        },
        cancelCreate = () => setMyStates({ createName: "", createPrice: "", createWeight: "", createWeights: [], createIngredients: 1, createNew: false, createAlias: "" }),
        changeWeight = ({ target: { value, name } }) => {
            if (isWeight(value)) {
                const myWeight = name === "createWeight" ? createWeights : editWeights
                return setMyStates({ [name + 's']: myWeight.concat(isWeight(value)), [name]: '' })
            }
            handleInputChange({ target: { value, name } })
        },
        deleteWeight = ({ from, data }) => () => {
            const index = getIndexFromArray(from === "createWeights" ? createWeights : editWeights, data, ['amount', 'unity', 'name'])
            setMyStates({
                [from]: from === "createWeights" ?
                    createWeights.slice(0, index).concat(createWeights.slice(index + 1)) :
                    editWeights.slice(0, index).concat(editWeights.slice(index + 1))
            })
        },
        getData = () => {
            return wrappers.map(({ name, price, _id, weight, ingredients, alias }) => {
                return {
                    id: _id,
                    name: (editId === _id) ?
                        <Input className="text-right" name="editName" placeholder="Nombre" onChange={handleInputChange} value={editName} /> :
                        name,
                    alias: (editId === _id) ?
                        <Input className="text-right" name="editAlias" placeholder="Alias" onChange={handleInputChange} value={editAlias} /> :
                        alias,
                    price: (editId === _id) ?
                        <Input className="text-right" name="editPrice" placeholder="Precio" onChange={beforChange} value={editPrice} /> :
                        numberToCLPFormater(price),
                    ingredients: (editId === _id) ?
                        <Input className="text-right" name="editIngredients" placeholder="Ingredientes" min="1" max="3" onChange={handleInputChange} value={editIngredients} type="number" /> :
                        ingredients,
                    weight: (editId === _id) ?
                        <Row>
                            <Col md={editWeights.length >= 1 ? 6 : 1} className="d-flex flex-column">
                                {editWeights.map(({ name, unity, amount }, k) => (
                                    <Badge key={k} href="#" onClick={deleteWeight({ from: 'editWeights', data: { name, unity, amount } })}
                                        color="success">{name} {amount}{unity}</Badge>)
                                )}
                            </Col>
                            <Col md={editWeights.length >= 1 ? 6 : 11}>
                                <Input name="editWeight" value={editWeight} placeholder="Gramaje (ej: palta:50gr,salmon:30gr)" onChange={changeWeight} />
                            </Col>
                        </Row>
                        :
                        <div className="d-flex flex-column" >
                            {weight.map(({ name, unity, amount }, k) => (
                                <Badge key={k} color="primary">{name} {amount}{unity}</Badge>
                            ))}
                        </div>,
                    actions:
                        (editId === _id) ?
                            <div className="actions-right">
                                <Button onClick={confirmModify} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-check-2 bg-success" />
                                </Button>
                                <Button onClick={cancelModify} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                            :
                            <div className="actions-right">
                                <Button onClick={modify(_id, name, price, weight, ingredients, alias)} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                                <Button onClick={remove({ name, price, id: _id })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                }
            })
        },
        getColums = () => {
            let columns = [
                {

                    Header: "Nombre",
                    accessor: "name",
                    className: "text-center",
                    Footer: createNew ?
                        <Input name="createName" value={createName} placeholder="Nombre" onChange={handleInputChange} /> :
                        null
                },
                {

                    Header: "Alias",
                    accessor: "alias",
                    className: "text-center",
                    Footer: createNew ?
                        <Input name="createAlias" value={createAlias} placeholder="Alias" onChange={handleInputChange} /> :
                        null
                },
                {
                    Header: "Precio",
                    accessor: "price",
                    className: "mx-2 text-right",
                    Footer: createNew ? <Input name="createPrice" className="text-right" value={createPrice} placeholder="Precio" onChange={beforChange} /> :
                        null

                },
                {
                    Header: "Ingredientes",
                    accessor: "ingredients",
                    className: "mr-2 text-right",
                    Footer: createNew ? <Input name="createIngredients" type="number" min="1" max="4" className="text-right" value={createIngredients} placeholder="Ingredientes" onChange={handleInputChange} /> :
                        null

                },
                {
                    Header: "Gramaje",
                    accessor: "weight",
                    className: "text-center",
                    filterable: false,
                    Footer: createNew ?
                        <Row>
                            <Col md={createWeights.length >= 1 ? 6 : 1} className="d-flex flex-column">
                                {createWeights.map(({ name, unity, amount }, k) => (
                                    <Badge key={k} href="#" onClick={deleteWeight({ from: 'createWeights', data: { name, unity, amount } })}
                                        color="success">{name} {amount}{unity}</Badge>)
                                )}
                            </Col>
                            <Col md={createWeights.length >= 1 ? 6 : 11}>
                                <Input name="createWeight" value={createWeight} placeholder="Gramaje (ej: palta:50gr,salmon:30gr)" onChange={changeWeight} />
                            </Col>
                        </Row> :
                        null
                }
            ]
            if (userData.type === "administrador")
                columns.push({
                    Header: "Acciones",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Footer:
                        <Row>
                            {createNew ?
                                <>
                                    <Col md={6}>
                                        <Button size="sm" color="success" onClick={confirmCreate}>Guardar</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button size="sm" color="danger" onClick={cancelCreate}>Cancelar</Button>
                                    </Col>
                                </>
                                :
                                <Col md={6} >
                                    <Button size="sm" onClick={createWrapper}>Crear nuevo</Button>
                                </Col>
                            }
                        </Row>
                })
            return columns;
        }

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de envolturas</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColums()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (
    <WrapperProvider>
        <FormProvider initialValues={{ editId: "", editName: "", editPrice: "", editIngredients: 1, editWeight: "", editWeights: [], editAlias: "", createName: "", createPrice: "", createIngredients: 1, createWeight: "", createWeights: [], createNew: false, createAlias: "", MyAlert: null }}>
            <Wrappers history={history} />
        </FormProvider>
    </WrapperProvider>
)