import React, { useContext } from 'react'
import { Form, Row, Col, Button, Input, CardBody, CardHeader, Card, CardFooter, FormGroup, CardTitle } from "reactstrap";
import Switch from "react-bootstrap-switch";
import { numberToCLPFormater } from 'numbertoclpformater'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import PromotionProvider, { PromotionContext } from '@Contexts/promotion'
import WrapperProvider, { WrapperContext } from '@Contexts/wrapper'
import ProductProvider, { ProductContext } from '@Contexts/product'
import FormProvider, { FormContext } from '@Contexts/form'
import { getIndexFromArray, getNumber, getCancelAlert, getSuccessAlert } from '@Config/util'

import './style.css'

const AddPromotion = ({ history }) => {
    const
        { values: { createName, createPrice, createSelected, createWrappers, createProducts, MyAlert }, handleInputChange, setMyStates } = useContext(FormContext),
        { addPromotion } = useContext(PromotionContext),
        { wrappers } = useContext(WrapperContext),
        { products } = useContext(ProductContext),
        animatedComponents = makeAnimated(),
        switchChange = ({ props: { name } }, value) => handleInputChange({ target: { value, name } }),
        changeNumber = ({ target: { value, name } }) => handleInputChange({ target: { value: numberToCLPFormater(getNumber(value)), name } }),
        beforeChange = (_, { action, option, removedValue, name }) => {
            const myArray = name === "createWrappers" ? createWrappers : createProducts
            if (action === "select-option" && option) {
                const index = getIndexFromArray(myArray, option, ['_id'])
                if (index < 0)
                    setMyStates({ [name]: myArray.concat({ value: option.value, label: option.label, amount: 1, _id: option.value }) })
                else
                    setMyStates({
                        [name]: myArray.slice(0, index)
                            .concat({ ...option, amount: myArray[index].amount + 1 })
                            .concat(myArray.slice(index + 1))
                    })
            }
            if (action === "remove-value" && removedValue) {
                const index = getIndexFromArray(myArray, removedValue, ['_id'])
                if (index >= 0) {
                    setMyStates({
                        [name]: myArray.slice(0, index)
                            .concat(myArray.slice(index + 1))
                    })
                }
            }
        },
        submit = async e => {
            e.preventDefault()
            const { ok, msg } = await addPromotion({ name: createName, price: getNumber(createPrice), selected: createSelected, wrappers: createWrappers.map(({ _id, amount }) => ({ wrapper: _id, amount })), products: createProducts.map(({ _id, amount }) => ({ product: _id, amount })) })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "La promoción ha creada!, enseguida será redireccionado.", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) setTimeout(() => history.push('/app/promotions'), 3000)
        },
        hideAlert = () => setMyStates({ MyAlert: null })



    return <div className="content">
        {MyAlert}
        <Form onSubmit={submit}>
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h3">Añadir promoción</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <label>Nombre</label>
                                    <FormGroup>
                                        <Input value={createName} name="createName" onChange={handleInputChange} />
                                    </FormGroup>

                                    <label>Precio</label>
                                    <FormGroup>
                                        <Input name="createPrice" value={createPrice} onChange={changeNumber} required />
                                    </FormGroup>

                                </Col>
                                <Col md={6}>
                                    <label>Rolls</label>
                                    <FormGroup>
                                        <Select
                                            menuPosition="fixed"
                                            isClearable
                                            components={animatedComponents}
                                            isMulti
                                            name="createWrappers"
                                            onChange={beforeChange}
                                            value={createWrappers.map(({ label, amount, _id }, value) => ({ label: amount + " " + label, value, _id }))}
                                            options={wrappers.map(({ _id, name }) => ({ value: _id, label: name, _id }))}
                                        />
                                    </FormGroup>
                                    <label>Productos adicionales</label>
                                    <FormGroup>
                                        <Select
                                            menuPosition="fixed"
                                            isClearable
                                            components={animatedComponents}
                                            isMulti
                                            name="createProducts"
                                            onChange={beforeChange}
                                            value={createProducts.map(({ label, amount, _id }, value) => ({ label: amount + " " + label, value, _id }))}
                                            options={products.map(({ _id, name }) => ({ value: _id, label: name, _id }))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>

                                    <FormGroup className="text-center">
                                        <label className="m-4">¿a elección del chef?</label>
                                        <Switch
                                            offText={<i className="tim-icons icon-simple-remove" />}
                                            onText={<i className="tim-icons icon-check-2" />}
                                            value={createSelected}
                                            onChange={switchChange}
                                            name="createSelected"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                        <CardFooter className="text-right p-3">
                            <Button type="submit">Guardar</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Form>
    </div>
}

export default props => (
    <PromotionProvider>
        <WrapperProvider>
            <ProductProvider>
                <FormProvider initialValues={{ createName: "", createPrice: "", createSelected: false, createWrappers: [], createProducts: [], MyAlert: null }}>
                    <AddPromotion {...props} />
                </FormProvider>
            </ProductProvider>
        </WrapperProvider>
    </PromotionProvider>
)