import { gql } from 'apollo-boost'

export default {
    GET_WRAPPERS: gql`
        query getWrappers {
            getWrappers {
                _id
                name
                price
                ingredients
                alias
                weight{
                    name
                    amount
                    unity
                }
                suggesteds{
                        _id
                        name
                    }
            }
        }    
    `,
    ADD_WRAPPER: gql`
        mutation addWrapper($name: String!, $price: Int!, $ingredients: Int!, $weight:[WeightInput!]!, $alias: String!) {
            addWrapper(input: {name: $name, price: $price, ingredients: $ingredients, weight: $weight, alias: $alias}){
                _id
                name
                price
                ingredients
                alias
                weight{
                    name
                    amount
                    unity
                }
            }
        }
    `,
    MODIFY_WRAPPER: gql`
        mutation modifyWrapper($name: String!, $price: Int!, $ingredients: Int!, $_id: ObjectId!, $weight: [WeightInput!]!, $alias: String!) {
            modifyWrapper(input:{name: $name, price: $price, ingredients: $ingredients, _id: $_id, weight: $weight, alias: $alias})
        }
    `,
    REMOVE_WRAPPER: gql`
        mutation removeWrapper($_id: ObjectId!) {
            removeWrapper(input: $_id)
        }
    `,
    GET_WRAPPERS_SUGGESTED: gql`
        query getWrappers {
                getWrappers {
                    _id
                    name
                    ingredients
                    suggesteds{
                        _id
                        name
                    }
                }
            } 
    `,
    SET_SUGGESTEDS_WRAPPERS: gql`
        mutation setSuggested($_id: ObjectId, $suggesteds: [ObjectId!]!) {
            setSuggested(input: {_id: $_id, suggesteds: $suggesteds})
        }
    `
}