import React from 'react'
import moment from 'moment'
import { numberToCLPFormater } from 'numbertoclpformater'
import { getNumber, getTip } from '@Config/util'

import { Badge } from "reactstrap";


export const getHeaders = states => {
    let headers = [
        {
            Header: "Correlativo",
            accessor: "index",
            className: "text-center"
        },
        {
            Header: "Estado",
            accessor: "state",
            className: "text-center"
        }
    ]

    if (states.stateDelivery) {
        headers.push(
            {
                Header: "Delivery",
                accessor: "delivery",
                className: "text-center"
            }
        )
    }

    if (states.stateWrappers) {
        headers.push(
            {

                Header: "Rolls",
                accessor: "wrappers",
                className: "text-center"
            }
        )
    }

    if (states.stateDate) {
        headers.push(
            {
                Header: 'Fecha de Inicio',
                accessor: ({ createdAt }) => moment(createdAt).format('DD-MM-YYYY HH:mm '), id: '_from', sortMethod: (a, b) => (moment(a, 'D MMM YYYY').unix() - moment(b, 'D MMM YYYY').unix())
            }
        )
    }
    if (states.statePayment) {
        headers.push(
            {
                Header: "Tipo de Pago",
                accessor: "payment",
                className: "text-center"

            }
        )
    }

    if (states.stateClient) {
        headers.push(
            {
                Header: "Cliente",
                accessor: "client",
                className: "text-center",
                filterMethod: ({ value }, row) => {
                    let data = row.client.props.children, toReturn = false
                    for (let n in data) {
                        console.log(data[n].props.children)
                        for (let m in data[n].props.children) {
                            if (data[n].props.children[m].toString().toLowerCase().includes(value.toLowerCase())) toReturn = true
                        }
                    }
                    return toReturn
                }
            }
        )
    }

    if (states.stateUser) {
        headers.push(
            {
                Header: "Usuario",
                accessor: "user",
                className: "text-center"
            }
        )
    }


    if (states.stateProducts) {
        headers.push(
            {
                Header: "Productos",
                accessor: "products",
                filterable: false
            }
        )
    }

    if (states.statePromotions) {
        headers.push(
            {
                Header: "Promociones",
                accessor: "promotions",
                filterable: false
            }
        )
    }

    if (states.stateNote) {
        headers.push(
            {
                Header: "Nota",
                accessor: "note",
                filterable: false
            }
        )
    }

    if (states.stateOut) {
        headers.push(
            {
                Header: "Hora entrega",
                accessor: "out",
                filterable: false
            }
        )
    }

    if (states.stateDiscount) {
        headers.push(
            {
                Header: "Descuento aplicado",
                accessor: "discount",
                filterable: false
            }
        )
    }

    if (states.stateTip) {
        headers.push(
            {
                Header: "Propina",
                accessor: "tip",
                filterable: false
            }
        )
    }


    headers.push(
        {
            Header: "Total",
            id: 'total',
            accessor: data => { return numberToCLPFormater(data.total) },
            className: "mx-2 text-center",
            sortMethod: (a, b) => {
                console.log("hola", a, b)
                return parseInt(getNumber(a)) > parseInt(getNumber(b)) ? 1 : -1;
            },
            Cell: (props) => props.value
        },
        {
            Header: "Acciones",
            accessor: "actions",
            sortable: false,
            filterable: false,
            className: "text-center"
        }
    )

    return headers
}

export const getDataRow = ({ total, tip, payment, createdAt, note, out, promotions, user, client, wrappers, products, index, delivery, state }) => {
    return {
        index: index,
        total: total,
        tip: <div> {getTip(tip.type)} : {numberToCLPFormater(parseInt(tip.amount))} </div>,
        note: note,
        out: new Date(out).getHours() + ":" + new Date(out).getMinutes(),
        payment: payment, 
        delivery: delivery ? "Si" : "No",
        createdAt: createdAt,
        client: <div className="d-flex flex-column">
            <Badge color="success">{client.name} </Badge>
            <Badge color="danger">+56(9){client.phone} </Badge>
            <Badge color="warning">{client.address} </Badge>
        </div>,
        user: user.name,
        wrappers:
            <div className="d-flex flex-column">
                {
                    wrappers.map(({ wrapper: { name }, ingredients }, k) => (

                        <div className="d-flex flex-column" key={name + k}>
                            <Badge key={k} color="success">{name}</Badge>
                            {
                                ingredients.map(({ alias }, e) => (

                                    <Badge key={alias} color="danger">{alias}</Badge>
                                ))}
                        </div>
                    ))
                }
            </div>
        ,
        products:
            <div className="d-flex flex-column">
                {
                    products.map(({ product: { name }, amount }, k) => (
                        <Badge key={k} color="primary">[{amount}] {name}</Badge>
                    ))

                }

            </div>,

        promotions:
            <div className="d-flex flex-column">
                {
                    promotions.map(({ promotion: { name }, ingredients }, k) => (

                        <div key={name + k}>
                            <Badge key={k} color="success">{name}</Badge>
                            {
                                ingredients.map(({ wrapper: { _id, name }, ingredients }, e) => (
                                    <div key={e}>
                                        <Badge key={name} color="warning">{name}</Badge>
                                        {
                                            ingredients.map(({ alias }, e) => (
                                                <Badge key={alias} color="danger">{alias}</Badge>
                                            ))
                                        }

                                    </div>
                                ))}
                        </div>
                    ))
                }
            </div>

    }
}