// Dependencies
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Input, InputGroup, NavbarBrand, Navbar, NavLink, Nav, Container, Modal, UncontrolledTooltip } from "reactstrap";
import { useCookies } from 'react-cookie';

import { $logo } from '../../config'

export default ({ location, handleMiniClick, sidebarOpened, toggleSidebar, brandText, history }) => {
  // Initial states

  const [, , removeCookie] = useCookies(['userData']);
  const [{ collapseOpen, modalSearch, color }, setState] = useState({ collapseOpen: false, modalSearch: false, color: "navbar-transparent" });
  const actualState = { collapseOpen, modalSearch, color }
  // Setting listener on create and destroy component
  useEffect(() => {
    const updateColor = () =>
      setState({ ...actualState, color: (window.innerWidth < 993 && collapseOpen) ? "bg-white" : "navbar-transparent" });
    window.addEventListener("resize", updateColor);

    return () => window.removeEventListener("resize", updateColor);
  }, [setState, actualState, collapseOpen])
  // Functions
  const
    toggleCollapse = () => setState({ ...actualState, color: collapseOpen ? "navbar-transparent" : "bg-white", collapseOpen: !collapseOpen }),
    toggleModalSearch = () => setState({ ...actualState, modalSearch: !modalSearch });

  // Rendering
  return (
    <>
      <Navbar className={classNames("navbar-absolute", { [color]: location.pathname.indexOf("full-screen-map") === -1 })} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button className="minimize-sidebar btn-just-icon" color="link" id="tooltip209599" onClick={handleMiniClick}>
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip209599" placement="right">
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div className={classNames("navbar-toggle d-inline", { toggled: sidebarOpened })}>
              <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand>
              {brandText}
            </NavbarBrand>
          </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>

              <InputGroup className="search-bar" tag="li">
                <Button color="link" data-target="#searchModal" data-toggle="modal" id="search-button" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Buscar</span>
                </Button>
              </InputGroup>

              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" data-toggle="dropdown" nav>
                  {/* NEW NOTIFICATIONS */}
                  <div className="notification d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notificaciones</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      No tienes notificaciones.
                    </DropdownItem>
                  </NavLink>

                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" data-toggle="dropdown" nav onClick={e => e.preventDefault()}>
                  <div className="photo">
                    <img alt="..." src={`/${$logo()}`} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Nombre usuario</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {/* <NavLink tag="li">
                    <DropdownItem className="nav-item">Perfil</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Configuraciones</DropdownItem>
                  </NavLink> */}
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem onClick={(e) => { e.preventDefault(); removeCookie('userData', { path: '/' }); removeCookie('userData', { path: '/app' }); removeCookie('userData', { path: '/auth' }); }} className="nav-item">Salir</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      <Modal modalClassName="modal-search" isOpen={modalSearch} toggle={toggleModalSearch}>
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="Buscar" type="text" />
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggleModalSearch}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>

    </>
  );
}
