import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';

import Mutations from '@Graphql/wrapper'
import { errorHandler, lookError } from '@Config/util'

export const WrapperContext = createContext({
    addWrapper: async () => undefined,
    modifyWrapper: async () => undefined,
    removeWrapper: async () => undefined,
    getSuggested: async () => undefined,
    setSuggested: async () => undefined,
    wrappers: [],
    suggesteds: []
})

const WrapperProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient({}),
        [wrappers, setWrappers] = useState([]),
        [loaded, setLoaded] = useState(false),
        [suggesteds, setSuggesteds] = useState([])

    useEffect(() => {
        if (!loaded) {
            async function getWrappers() {
                try {
                    await resetStore()
                    const { data } = await query({ query: Mutations.GET_WRAPPERS })
                    setWrappers(data.getWrappers)
                } catch (e) {
                    return lookError(e)
                }
            }
            getWrappers();
            setLoaded(true)
        }
    }, [setLoaded, loaded, query, setWrappers, resetStore])

    async function addWrapper({ name, price, ingredients, weight, alias }) {
        try {
            const { data: { addWrapper } } = await mutate({
                mutation: Mutations.ADD_WRAPPER,
                variables: { name, price: parseInt(price), ingredients: parseInt(ingredients), weight, alias }
            })
            setWrappers(wrappers.concat(addWrapper))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyWrapper({ name, price, ingredients, _id, weight, alias }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_WRAPPER,
                variables: { name, price: parseInt(price), ingredients: parseInt(ingredients), _id, weight, alias }
            })
            const index = wrappers.map(e => e._id).indexOf(_id)
            setWrappers(wrappers.slice(0, index).concat({ name, price, ingredients, _id, weight, alias }).concat(wrappers.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removeWrapper({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_WRAPPER,
                variables: { _id }
            })
            const index = wrappers.map(e => e._id).indexOf(_id)
            setWrappers(wrappers.slice(0, index).concat(wrappers.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function getSuggested() {
        try {
            await resetStore()
            const { data } = await query({ query: Mutations.GET_WRAPPERS_SUGGESTED })
            setSuggesteds(data.getWrappers)
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function setSuggested({ _id, suggested }) {
        try {
            const index = suggesteds.map(({ _id }) => _id).indexOf(_id)
            setSuggesteds(
                suggesteds.slice(0, index)
                    .concat({ ...suggesteds[index], suggesteds: suggested.map(({ label, value }) => ({ _id: value, name: label })) })
                    .concat(suggesteds.slice(index + 1))
            )
            await mutate({
                mutation: Mutations.SET_SUGGESTEDS_WRAPPERS,
                variables: { _id, suggesteds: suggested.map(({ value }) => value).filter(a => a) }
            })

        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        addWrapper,
        wrappers,
        modifyWrapper,
        removeWrapper,
        suggesteds,
        getSuggested,
        setSuggested
    }

    return (
        <WrapperContext.Provider value={context}>
            {children}
        </WrapperContext.Provider>
    )
}

WrapperProvider.propTypes = {
    children: element
}

export default WrapperProvider