import React from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { numberToCLPFormater } from 'numbertoclpformater'

export default ({ title, value, offMoney }) => (
    <Col lg="4" md="6" >
        <Card className="card-stats">
            <CardBody>
                <Row>
                    <Col xs="5">
                        <div className="info-icon text-center icon-warning">
                            <i className="tim-icons icon-coins" />
                        </div>
                    </Col>
                    <Col xs="7">
                        <div className="numbers">
                            <p className="card-category">{title}</p>
                            <CardTitle tag="h3">{offMoney ? value : numberToCLPFormater(value)}</CardTitle>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Col>
)
