import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';

import Mutations from '@Graphql/product'
import { errorHandler, lookError } from '@Config/util'

export const ProductContext = createContext({
    addProduct: async () => undefined,
    modifyProduct: async () => undefined,
    removeProduct: async () => undefined,
    products: []
})

const ProductProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient(),
        [products, setProducts] = useState([]),
        [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            async function getProducts() {
                try {
                    await resetStore()
                    const { data } = await query({ query: Mutations.GET_PRODUCTS })
                    setProducts(data.getProducts)
                } catch (e) {
                    return lookError(e)
                }
            }
            getProducts();
            setLoaded(true)
        }
    }, [setLoaded, loaded, query, setProducts, resetStore])

    async function addProduct({ name, price, weight, favorite, barcode }) {
        try {
            const { data: { addProduct } } = await mutate({
                mutation: Mutations.ADD_PRODUCT,
                variables: { name, price: parseInt(price), weight, favorite, barcode }
            })
            setProducts(products.concat(addProduct))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyProduct({ name, price, _id, weight, favorite, barcode }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_PRODUCT,
                variables: { name, price: parseInt(price), _id, weight, favorite, barcode }
            })
            const index = products.map(e => e._id).indexOf(_id)
            setProducts(products.slice(0, index).concat({ name, price, _id, weight, favorite, barcode }).concat(products.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removeProduct({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_PRODUCT,
                variables: { _id }
            })
            const index = products.map(e => e._id).indexOf(_id)
            setProducts(products.slice(0, index).concat(products.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        addProduct,
        products,
        modifyProduct,
        removeProduct
    }

    return (
        <ProductContext.Provider value={context}>
            {children}
        </ProductContext.Provider>
    )
}

ProductProvider.propTypes = {
    children: element
}

export default ProductProvider