import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';

import Mutations from '@Graphql/promotion'
import { errorHandler, lookError } from '@Config/util'

export const PromotionContext = createContext({
    getPromotion: async () => undefined,
    addPromotion: async () => undefined,
    modifyPromotion: async () => undefined,
    removePromotion: async () => undefined,
    promotions: []
})

const PromotionProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient(),
        [promotions, setPromotions] = useState([]),
        [loaded, setLoaded] = useState(false)


    useEffect(() => {
        if (!loaded) {
            async function getPromotions() {
                try {
                    await resetStore()
                    const { data } = await query({ query: Mutations.GET_PROMOTIONS })
                    setPromotions(data.getPromotions)
                } catch (e) {
                    return lookError(e)
                }
            }
            getPromotions();
            setLoaded(true)
        }
    }, [setLoaded, loaded, query, setPromotions, resetStore])


    async function getPromotion({ _id }) {
        try {
            const { data: { getPromotion } } = await query({ query: Mutations.GET_PROMOTION, variables: { _id } })
            console.log(getPromotion)
            return { ok: true, promotion: getPromotion ? getPromotion : {} }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function addPromotion({ name, price, selected, wrappers, products }) {
        try {
            const { data: { addPromotion } } = await mutate({
                mutation: Mutations.ADD_PROMOTION,
                variables: { name, price: parseInt(price), selected, wrappers, products }
            })
            setPromotions(promotions.concat(addPromotion))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyPromotion({ name, price, selected, wrappers, products, _id }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_PROMOTION,
                variables: { name, price: parseInt(price), selected, wrappers, products, _id }
            })
            const index = promotions.map(e => e._id).indexOf(_id)
            setPromotions(promotions.slice(0, index).concat({ name, price, selected, wrappers, products, _id }).concat(promotions.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removePromotion({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_PROMOTION,
                variables: { _id }
            })
            const index = promotions.map(e => e._id).indexOf(_id)
            setPromotions(promotions.slice(0, index).concat(promotions.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        getPromotion,
        addPromotion,
        modifyPromotion,
        removePromotion,
        promotions
    }

    return (
        <PromotionContext.Provider value={context}>
            {children}
        </PromotionContext.Provider>
    )
}

PromotionProvider.propTypes = {
    children: element
}

export default PromotionProvider