import React from "react";
import Select from "react-select";
import { Row, Col, Card, CardBody, CardHeader, Button, Input } from 'reactstrap'
import { numberToCLPFormater } from 'numbertoclpformater'
import { getCancelAlert, getField } from '@Config/util'

const emptyPromo = { selectPromotion: { value: "", label: "" }, promotion: {}, wrappers: [] }

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: []
        };
    }
    isValidated = () => {
        if (!this.state.promotions.length) return true
        let exit = true, totalPromotions = []
        this.state.promotions.map(({ wrappers, promotion: { selected }, selectPromotion: { label } }, promo) => {
            if (selected) return null
            wrappers.map(({ wrapper, ingredients }) => {
                if (wrapper && ingredients) {
                    if (ingredients.length) {
                        ingredients.map(({ value, label }) => {
                            if (!value && !label) exit = false
                            return null
                        })
                    }
                } else exit = false;
                return null
            })
            return null
        })
        if (!exit) this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Debe llenar todos los campos." }))
        else {
            const promotions = this.state.promotions.map(({ promotion: { _id, selected, price }, wrappers }) => {
                const ingredients = wrappers.map(({ wrapper, ingredients }) => {
                    const ing = ingredients.map(({ value }) => value)
                    return { wrapper, ingredients: ing }
                })
                return { promotion: _id, ingredients: selected ? [] : ingredients }
            })
            promotions.map(({ promotion, ingredients }) => {
                let ing = [], prods = []
                const namePromo = getField({ array: this.props.promotions, field: 'name', id: promotion })
                const products = getField({ array: this.props.promotions, field: 'products', id: promotion })
                products.map(({ product: { name }, amount }) => {
                    prods.push(`[${amount}] ${name}`)
                    return null
                })
                if (!ingredients.length) {
                    const wraps = getField({ array: this.props.promotions, field: 'wrappers', id: promotion })
                    wraps.map(({ wrapper, amount }) => {
                        for (let i = 0; i < amount; i++) ing.push({ name: wrapper.name, ingredients: [] })
                        return null
                    })
                } else {
                    ingredients.map(ingr => {
                        const wrapName = getField({ array: this.props.wrappers, field: 'name', id: ingr.wrapper })
                        const wrapIngs = ingr.ingredients.map(ingId => {
                            const ingName = getField({ array: this.props.ingredients, field: 'name', id: ingId })
                            return ingName
                        })
                        ing.push({ name: wrapName, ingredients: wrapIngs })
                        return null
                    })
                }
                totalPromotions.push({ name: namePromo, wrappers: ing, products: prods })
                return null
            })
            let total = 0;
            promotions.map(({ promotion }) => {
                const price = getField({ array: this.props.promotions, field: 'price', id: promotion })
                if (price) total += parseInt(price)
                return null
            })
            this.props.setSell({ ...this.props.sell, promotions })
            this.props.setInfo({ ...this.props.info, promotions: totalPromotions, total2: total })
        }
        return exit
    }
    addPromotion = () => {
        this.setState({ promotions: this.state.promotions.concat(emptyPromo) })
    }
    removePromotion = n => () => this.setState({ promotions: this.state.promotions.slice(0, n).concat(this.state.promotions.slice(n + 1)) })
    selectPromotions = n => (
        <Select
            className="react-select info mb-2"
            classNamePrefix="react-select"
            name={"select_promotion" + n}
            value={this.state.promotions[n].selectPromotion}
            onChange={selectPromotion => {
                const promotion = this.props.promotions.filter(({ _id }) => _id === selectPromotion.value)[0]
                let wraps = [], wrappers = []
                promotion.wrappers.map(({ amount, wrapper: { ingredients, _id, suggesteds} }) => {
                    for (let i = 1; i <= amount; i++) {
                        wraps.push({ _id, ingredients, suggesteds })
                    }
                    return null
                })
                wraps.map(({ _id, ingredients, suggesteds }) => {
                    let ing = []
                    for (let i = 0; i < ingredients; i++) ing.push(suggesteds[i] ? { label: suggesteds[i].name, value: suggesteds[i]._id } : {})
                    wrappers.push({ wrapper: _id, ingredients: ing })
                    return null
                })

                this.setState({
                    promotions: this.state.promotions.slice(0, n).concat({ selectPromotion, promotion, wrappers }).concat(this.state.promotions.slice(n + 1))
                })
            }}
            options={this.props.promotions.map(({ _id, name, wrappers, products, price, selected }) => {
                let myLabel = `${name} ${numberToCLPFormater(price)}: `
                for (let prod in products) {
                    myLabel += `, [${products[prod].amount}]:${products[prod].product.name}`
                }
                for (let wrap in wrappers) {
                    myLabel += `, [${wrappers[wrap].amount}]:${wrappers[wrap].wrapper.name}`
                }
                myLabel += selected ? ", ingredientes a elección del chef." : ", ingredientes a elección."
                return { value: _id, label: myLabel }
            })}
            placeholder="Elija una promoción"
        />
    )
    getIngredients = (nPromo, nWrap, nIng) => (
        <Select
            className="react-select info mb-4"
            classNamePrefix="react-select"
            name={"select_ingredient" + nPromo + nWrap + nIng}
            value={this.state.promotions[nPromo].wrappers[nWrap].ingredients[nIng]}
            onChange={value =>
                this.setState({
                    promotions: this.state.promotions.slice(0, nPromo)
                        .concat({
                            ...this.state.promotions[nPromo],
                            wrappers: this.state.promotions[nPromo].wrappers.slice(0, nWrap)
                                .concat({
                                    ...this.state.promotions[nPromo].wrappers[nWrap],
                                    ingredients: this.state.promotions[nPromo].wrappers[nWrap].ingredients.slice(0, nIng)
                                        .concat(value)
                                        .concat(this.state.promotions[nPromo].wrappers[nWrap].ingredients.slice(nIng + 1))
                                })
                                .concat(this.state.promotions[nPromo].wrappers.slice(nWrap + 1))
                        })
                        .concat(this.state.promotions.slice(nPromo + 1))
                })
            }
            options={this.props.ingredients.filter(({ type }) => type === "tipo " + (nIng + 1)).map(({ name, _id }) => ({ label: name, value: _id }))}
            placeholder={"Ingrediente tipo " + (nIng + 1)}
        />
    )
    getPromotions = (prom, n) => {
        if (!Object.keys(prom).length) return null
        const { name, products, selected, wrappers } = prom
        return (
            <Row>
                <Col md={12}><h3 className="info-text">{name}</h3></Col>
                {products.length ? <Col md={12}><h5 className="info-text">Productos</h5></Col> : null}
                <Col md={12}>{this.getProducts(products)}</Col>
                {wrappers.length ? <Col md={12}><h5 className="info-text">Rolls {selected ? "A elección del chef." : ""} </h5></Col> : null}
                <Col md={12}> {this.getWrappers(wrappers, selected, n)} </Col>
            </Row>
        )
    }

    getProducts = prod => prod.map(({ product, amount }, k) => <Row key={k} className="my-1"><Col md={6}> <Input value={product.name} disabled /> </Col><Col md={6}><Input value={amount} disabled /> </Col></Row>)
    getWrappers = (wraps, selected, onPromo) => {
        let wrapsView = []


        wraps.map(({ amount, wrapper: { name, ingredients } }) => {
            for (let i = 1; i <= amount; i++) {
                wrapsView.push({ name, ingredients })
            }
            return null
        })

        return wrapsView.map(({ name, ingredients }, onWrap) => {
            return (
                <Row key={onWrap} className="my-1">
                    <Col md={6}> <Input value={name} disabled /> </Col>
                    {selected ? null :
                        <Col md={6} className="d-flex flex-column">
                            {this.getIngredients(onPromo, onWrap, 0)}
                            {ingredients > 1 ? this.getIngredients(onPromo, onWrap, 1) : null}
                            {ingredients > 2 ? this.getIngredients(onPromo, onWrap, 2) : null}
                            {ingredients > 3 ? this.getIngredients(onPromo, onWrap, 3) : null}
                        </Col>
                    }
                </Row>
            )
        })

    }



    render() {
        const { promotions } = this.state
        return (
            <div className="content">
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <Card>
                            <CardHeader><h3 className="title">¿Desea añadir alguna promoción?</h3></CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {
                                            promotions.map(({ promotion }, k) => (
                                                <Row key={k} >
                                                    <Col md={10} className="mb-2" >
                                                        {this.selectPromotions(k)}
                                                        {this.getPromotions(promotion, k)}
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button onClick={this.removePromotion(k)} className="btn-icon btn-link like">
                                                            <i className="tim-icons icon-simple-remove bg-danger" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </Col>
                                    <Col className="my-3 text-center" md={12}> <Button onClick={this.addPromotion}>Añadir</Button> </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}