import { gql } from 'apollo-boost'

export default {
    GET_PROMOTION: gql`
        query getPromotion($_id: ObjectId!) {
            getPromotion(id: $id){
                _id
                name
                price
                selected
                wrappers {
                  wrapper {
                    _id
                    name
                    ingredients
                    weight {
                      name
                      amount
                      unity
                    }
                  }
                  amount
                }
                products {
                  product {
                    _id
                    name
                    weight {
                      name
                      amount
                      unity
                    }
                  }
                  amount
                }
              }
        }    
    `,
    GET_PROMOTIONS: gql`
        query getPromotions {
            getPromotions{
                _id
                name
                price
                selected
                wrappers {
                  wrapper {
                    _id
                    name
                    ingredients
                    weight {
                      name
                      amount
                      unity
                    }
                    suggesteds{
                      _id
                      name
                    }
                  }
                  amount
                }
                products {
                  product {
                    _id
                    name
                    weight {
                      name
                      amount
                      unity
                    }
                  }
                  amount
                }
              }
        } 
    `,
    ADD_PROMOTION: gql`
        mutation addPromotion($name: String!, $price: Int!, $selected: Boolean!, $wrappers: [WrapperInp!]!, $products: [ProductInp!]!){
            addPromotion(input: { name: $name, price: $price, selected: $selected, wrappers: $wrappers, products: $products }){
                _id
                name
                price
                selected
                wrappers {
                  wrapper {
                    _id
                    name
                    weight {
                      name
                      amount
                      unity
                    }
                  }
                  amount
                }
                products {
                  product {
                    _id
                    name
                    weight {
                      name
                      amount
                      unity
                    }
                  }
                  amount
                }
              }
        }    
    `,
    MODIFY_PROMOTION: gql`
        mutation modifyPromotion($name: String!, $price: Int!, $selected: Boolean!, $wrappers: [WrapperInp!]!, $products: [ProductInp!]!, $_id: ObjectId!){
            modifyPromotion(input: { name: $name, price: $price, selected: $selected, wrappers: $wrappers, products: $products, _id: $_id })
        }
    `,
    REMOVE_PROMOTION: gql`
        mutation removePromotion($_id: ObjectId!){
            removePromotion(input: $_id )
        }
    `
}