// Dependencies
import React, { useState } from "react";
import classnames from "classnames";
import { Collapse, NavbarBrand, Navbar, Nav, Container } from "reactstrap";

export default ({ brandText }) => {
  // Initial States
  const [{ collapseOpen, color }, setState] = useState({ collapseOpen: false, color: "navbar-transparent" })
  // Functions
  const toggleCollapse = () => setState({ collapseOpen: !collapseOpen, color: !collapseOpen ? "bg-white" : "navbar-transparent" })
  // Rendering
  return (
    <Navbar className={classnames("navbar-absolute fixed-top", color)} expand="lg"      >
      <Container fluid>
        <div className="navbar-wrapper">
          <NavbarBrand>{brandText}</NavbarBrand>
        </div>
        <button aria-controls="navigation-index" aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-toggle="collapse" type="button" onClick={toggleCollapse}          >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse isOpen={collapseOpen} navbar>
          <Nav navbar className="ml-auto">
            {/* <NavItem>
              <NavLink to="/app/dashboard" className="nav-link text-primary">
                <i className="tim-icons icon-minimal-left" /> Back to Dashboard
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/auth/register" className="nav-link">
                <i className="tim-icons icon-laptop" /> Register
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/auth/login" className="nav-link">
                <i className="tim-icons icon-single-02" /> Login
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/auth/pricing" className="nav-link">
                <i className="tim-icons icon-coins" /> Pricing
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/auth/lock-screen" className="nav-link">
                <i className="tim-icons icon-lock-circle" /> Lock
                </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
