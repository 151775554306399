import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';
import { useCookies } from 'react-cookie'

import Mutations from '@Graphql/user'
import { errorHandler, lookError } from '@Config/util'

export const UserContext = createContext({
    login: async () => undefined,
    userInfo: {},
    users: []
})

const UserProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient()
    const [userInfo, setUserInfo] = useState({}),
        [users, setUsers] = useState([]),
        [, setCookie] = useCookies([])

    useEffect(() => {
        async function getUsers() {
            try {
                await resetStore()
                const { data } = await query({ query: Mutations.GET_USERS })
                setUsers(data.getUsers)
            } catch (e) {
                return lookError(e)
            }
        }
        getUsers()
    }, [query, setUsers, resetStore])

    async function login({ email, password }) {
        try {
            const { data: { auth } } = await mutate({
                mutation: Mutations.AUTH,
                variables: { email, password }
            })
            setUserInfo(auth)
            setCookie('userData', auth)
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function addUser({ name, email, password, type }) {
        try {
            const { data: { addUser } } = await mutate({
                mutation: Mutations.ADD_USER,
                variables: { name, email, password, type }
            })
            setUsers(users.concat(addUser))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyUser({ name, email, password, type, _id }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_USER,
                variables: { name, email, password, type, _id }
            })
            const index = users.map(e => e._id).indexOf(_id)
            setUsers(users.slice(0, index).concat({ name, email, password, type, _id }).concat(users.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removeUser({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_USER,
                variables: { _id }
            })
            const index = users.map(e => e._id).indexOf(_id)
            setUsers(users.slice(0, index).concat(users.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }


    const context = {
        login,
        userInfo,
        users,
        addUser,
        modifyUser,
        removeUser
    }

    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    )
}

UserProvider.propTypes = {
    children: element
}

export default UserProvider