import React, { useContext } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Input } from "reactstrap";
import Select from "react-select";
import { useCookies } from 'react-cookie';
import UserProvider, { UserContext } from '@Contexts/user'
import FormProvider, { FormContext } from '@Contexts/form'
import { getNumber, getCancelAlert, getSuccessAlert, getWarningAlert, infoBodyClient } from '@Config/util'

const Users = () => {
    const { users, modifyUser, removeUser, addUser } = useContext(UserContext),
        { values: { editId, editName, editEmail, editPassword, editType, createName, createEmail, createPassword, createType, createNew, MyAlert }, handleInputChange, setMyStates } = useContext(FormContext)
        
    const [{ userData }, ,] = useCookies(['userData']);
    const
        remove = ({ id, name, email }) => () => setMyStates({ MyAlert: getWarningAlert({ title: "¿Está seguro de eliminar el usuario?", confirm: () => confirmRemove(id), cancel: () => setMyStates({ MyAlert: getCancelAlert({ hideAlert }) }), body: infoBodyClient({ name, email }) }) }),
        confirmRemove = async _id => {
            const { ok, msg } = await removeUser({ _id })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "El usuario ha sido eliminado/a!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
        },
        modify = (id, name, email, password, type) => () => setMyStates({ editId: id, editName: name, editEmail: email, editPassword: "", editType: { value: type, label: type } }),
        confirmModify = async () => {
            await modifyUser({ name: editName, email: editEmail, password: editPassword, type: editType.value, _id: editId })
            setMyStates({ editId: "", editName: "", editEmail: "", editPassword: "", editType: "" })
        },
        cancelModify = () => setMyStates({ editId: "", editName: "", editEmail: "", editPassword: "", editType: "" }),
        hideAlert = () => setMyStates({ MyAlert: null }),
        create = () => { },
        beforChange = ({ target: { value, name } }) => handleInputChange({ target: { value: getNumber(value), name } }),
        createUser = () => setMyStates({ createName: "", createEmail: "", createPassword: "", createType: "", createNew: true }),
        confirmCreate = async () => {
            const { ok, msg } = await addUser({ name: createName, email: createEmail, password: createPassword, type: createType.value })
            setMyStates({ MyAlert: ok ? getSuccessAlert({ msg: "El usuario ha sido creado!", hideAlert }) : getCancelAlert({ hideAlert, msg }) })
            if (ok) cancelCreate()
        },

        cancelCreate = () => setMyStates({ createName: "", createEmail: "", createPassword: "", createType: "", createNew: false }),
        getData = () => {
            return users.map(({ name, email, password, type, _id }) => {
                return {
                    id: _id,
                    name: (editId === _id) ? <Input className="text-right" name="editName" placeholder="Nombre" onChange={beforChange} value={editName} /> : name,
                    email: (editId === _id) ? <Input className="text-right" name="editEmail" type="email" placeholder="contacto@example.com" onChange={handleInputChange} value={editEmail} /> : email,
                    password: (editId === _id) ? <Input className="text-right" name="editPassword" placeholder="*****" type="password" onChange={handleInputChange} value={editPassword} /> : "Editar para cambiar contraseña",
                    type: (editId === _id) ? <Select className="react-select info" classNamePrefix="react-select" name="editType" value={editType} onChange={(e) => handleInputChange({ target: { name: "editType", value: e } })} menuPosition="fixed" options={[{ label: "Administrador", value: "administrador" }, { label: "Cajero", value: "cajero" }]} placeholder="Tipo" /> : type,
                    actions:
                        (editId === _id) ?
                            <div className="actions-right">
                                <Button onClick={confirmModify} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-check-2 bg-success" />
                                </Button>
                                <Button onClick={cancelModify} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                            :
                            <div className="actions-right">
                                <Button onClick={modify(_id, name, email, password, type)} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                                <Button onClick={remove({ name, email, password, type, id: _id })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                };
            })
        },
        getColumns = () => {
            let columns = [
                {

                    Header: "Nombre",
                    accessor: "name",
                    className: "text-center",
                    Footer: createNew ? <Input name="createName" value={createName} placeholder="Nombre" onChange={handleInputChange} required /> : null
                },
                {
                    Header: "Correo",
                    accessor: "email",
                    className: "text-center",
                    Footer: createNew ? <Input name="createEmail" value={createEmail} type="email" placeholder="contacto@ejemplo.com" onChange={handleInputChange} required /> : null

                },
                {
                    Header: "Contraseña",
                    accessor: "password",
                    className: "text-center",
                    Footer: createNew ? <Input name="createPassword" value={createPassword} placeholder="****" type="password" onChange={handleInputChange} required /> : null,
                    sortable: false
                },
                {
                    Header: "Tipo",
                    accessor: "type",
                    className: "text-center",
                    Footer: createNew ? <Select menuPlacement="auto" className="react-select info text-center" classNamePrefix="react-select"
                        name="createType" value={createType}
                        onChange={(e) => handleInputChange({ target: { name: "createType", value: e } })}
                        menuPosition="fixed"
                        options={[
                            { label: "Administrador", value: "administrador" },
                            { label: "Cajero", value: "cajero" },


                        ]}
                        placeholder="Selecciona el tipo de usuario."
                    /> : null
                }]

                if (userData.type === "administrador")
                columns.push({
                    Header: "Acciones",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Footer:
                        <Row>
                            {createNew ?
                                <>
                                    <Col md={6}>
                                        <Button size="sm" color="success" onClick={confirmCreate}>Guardar</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button size="sm" color="danger" onClick={cancelCreate}>Cancelar</Button>
                                    </Col>
                                </>
                                :
                                <Col md={6} >
                                    <Button size="sm" onClick={createUser}>Crear nuevo</Button>
                                </Col>
                            }
                        </Row>
                    })
            return columns;
        }

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de usuarios</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColumns()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                                Footer={<div style={{ width: '100%', textAlign: 'right' }}><Button onClick={create}>Crear</Button></div>}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ({ history }) => (
    <UserProvider>
        <FormProvider initialValues={{ editId: "", editName: "", editEmail: "", editPassword: "", editType: "", createName: "", createEmail: "", createPassword: "", createType: "", createNew: false, MyAlert: null }}>
            <Users history={history} />
        </FormProvider>
    </UserProvider>
)