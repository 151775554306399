import { gql } from 'apollo-boost'

export default {
    GET_CLIENTS: gql`
        query getClients {
            getClients {
            _id
            name
            phone
            email
            address
            }
        }    
    `,
    ADD_CLIENT: gql`
        mutation addClient($name: String!, $phone: Int!, $email: String!, $address: String!) {
            addClient(input: {name: $name, phone: $phone, email: $email, address: $address}){
                _id
                name
                phone
                email
                address
            }
        }
    `,
    MODIFY_CLIENT: gql`
        mutation modifyClient($name: String!, $phone: Int!, $email: String!, $address: String!, $_id: ObjectId!) {
            modifyClient(input: {name: $name, phone: $phone, email: $email, address: $address, _id: $_id})
        }
    `,
    REMOVE_CLIENT: gql`
        mutation removeClient($_id: ObjectId!) {
            removeClient(input: $_id)
        }
    `
}