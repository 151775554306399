// Dependencies
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// Components and routes
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import Footer from "../../components/Footer/Footer";
import routes from "../../routes.js";

export default () => {
  // Setting effects on create component
  useEffect(() => { document.documentElement.classList.remove("nav-open") }, [])
  // Functions
  const
    getRoutes = routes => {
      return routes.map(({ collapse, views, layout, path, component }, key) => {
        if (collapse) return getRoutes(views)
        if (layout === "/auth")
          return <Route path={layout + path} component={component} key={key} />
        return null
      });
    },
    getActiveRoute = routes => {
      let activeRoute = "Default Brand Text";
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].views);
          if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
        } else
          if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) return routes[i].name;
      }
      return activeRoute;
    },
    getFullPageName = routes => {
      switch (getActiveRoute(routes)) {
        case "Pricing":
          return "pricing-page";
        case "Login":
          return "login-page";
        case "Register":
          return "register-page";
        case "Lock Screen":
          return "lock-page";
        default:
          return "";
      }
    }
    // Rendering
  return (
    <>
      <AuthNavbar brandText={getActiveRoute(routes)} />
      <div className="wrapper wrapper-full-page">
        <div className={"full-page " + getFullPageName(routes)}>
          <Switch>{getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    </>
  )
}