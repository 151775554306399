
import Dashboard from "./views/Dashboard";
import Printer from './views/Printer'
import Products from './views/Products'
import Ingredients from './views/Ingredients'
import Clients from './views/Clients'
import Users from './views/Users'
import Login from './views/Login'
import Wrappers from './views/Wrappers'
import WrappersSuggesteds from './views/Wrappers/Suggesteds'
import Sells from './views/Sells'
import Promotions from './views/Promotions'
import AddPromotion from './views/Promotions/Add'
import ModifyPromotion from './views/Promotions/Modify'
import SellAdd from './views/Sells/Add'
import SellSearch from './views/Sells/Search'
import Reports from './views/Reports'

const routes = [
  {
    path: "/dashboard",
    name: "Panel de Control",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/app",
    type: ["administrador", "cajero"]
  },
  {
    path: "/reports/:format/:from/:to",
    name: "Generar reportes",
    icon: "tim-icons icon-chart-pie-36",
    component: Reports,
    layout: "/app",
    type: ["administrador"],
    exclude:true
  },
  {
    path: "/reports",
    name: "Generar reportes",
    icon: "tim-icons icon-chart-pie-36",
    component: Reports,
    layout: "/app",
    type: ["administrador"]
  },  
  {
    name: "Productos",
    icon: "tim-icons icon-attach-87",
    path: "/products",
    component: Products,
    type: ["cajero", "administrador"],
    layout: "/app"
  },
  {
    collapse: true,
    name: "Envolturas",
    icon: "tim-icons icon-link-72",
    type: ["cajero", "administrador"],
    state: "wrappersCollapse",
    views: [
      {
        name: "Lista de envolturas",
        mini: "VP",
        path: "/wrappers",
        type: ["cajero", "administrador"],
        component: Wrappers,
        layout: "/app"
      },
      {
        name: "Sugeridos",
        mini: "VP",
        path: "/suggesteds",
        type: ["administrador"],
        component: WrappersSuggesteds,
        layout: "/app"
      }
    ]
  },
  {
    name: "Ingredientes",
    icon: "tim-icons icon-vector",
    type: ["cajero", "administrador"],
    path: "/ingredients",
    component: Ingredients,
    layout: "/app",
  },
  {
    collapse: true,
    name: "Promociones",
    icon: "tim-icons icon-gift-2",
    state: "promotionsCollapse",
    type: ["cajero", "administrador"],
    views: [
      {
        name: "Ver Promociones",
        mini: "VP",
        path: "/promotions",
        type: ["cajero", "administrador"],
        component: Promotions,
        layout: "/app"
      },
      {
        name: "Crear Promoción",
        mini: "CP",
        path: "/promotion/add",
        type: ["administrador"],
        component: AddPromotion,
        layout: "/app"
      },
      {
        exclude: true,
        path: "/promotion/modify/:id",
        type: ["cajero", "administrador"],
        component: ModifyPromotion,
        layout: "/app"
      }
    ]
  },
  {
    collapse: true,
    name: "Ventas",
    icon: "tim-icons icon-coins",
    path: "/sells",
    component: Sells,
    type: ["cajero", "administrador"],
    views: [
      {
        name: "Ver Ventas",
        path: "/sells/:page",
        component: Sells,
        type: ["cajero", "administrador"],
        layout: "/app",
        exclude: true
      },
      {
        name: "Ver Ventas",
        path: "/sells",
        component: Sells,
        type: ["cajero", "administrador"],
        layout: "/app",
        mini: "VV"
      },
      {
        name: "Crear Venta",
        path: "/sell/add",
        component: SellAdd,
        type: ["cajero", "administrador"],
        layout: "/app",
        mini: "CV"
      },
      {
        name: "Buscar ventas",
        path: "/sell/search",
        component: SellSearch,
        type: ["cajero", "administrador"],
        layout: "/app",
        mini: "BV"
      }
    ]
  },
  {
    name: "Usuarios",
    icon: "tim-icons icon-user-run",
    path: "/users",
    type: ["administrador"],
    component: Users,
    layout: "/app"
  },
  {
    name: "Clientes",
    icon: "tim-icons icon-single-02",
    path: "/clients",
    type: ["cajero", "administrador"],
    component: Clients,
    layout: "/app",
  },
  
  {
    exclude: true,
    name: "Inicio de Sesión",
    path: "/login",
    component: Login,
    layout: "/auth",
    type: ["cajero", "administrador"],
  },
  {
    exclude: true,
    path: '/print',
    type: ["cajero", "administrador"],
    component: Printer
  }
];

export default routes;
