import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Badge, FormGroup, Input } from 'reactstrap'
import ReactTable from "react-table";
import Select from "react-select";
import { numberToCLPFormater } from 'numbertoclpformater'
import Switch from "react-bootstrap-switch";
import ReactDatetime from "react-datetime";
import moment from 'moment'

import { getNumber, getCancelAlert, getSuccessAlert } from '@Config/util'


import './style2.css'

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment: null,
            delivery: false,
            out: new Date(),
            note: "",
            discount: 0,
            tip: {
                type: { label: "Efectivo", value: "cash" },
                amount: 0
            }
        };
    }

    isValidated = () => {
        if (this.state.payment) {
            if (this.state.payment.value) {
                if (!this.getTotal()) {
                    this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Creación cancelada, inrgese al menos un producto, roll o promoción para continuar la venta." }))
                    return false
                } else {
                    this.props.setAlert(getSuccessAlert({ hideAlert: this.props.hideAlert, msg: "Su pago ha sido creado, en breve será redireccionado" }))
                    this.props.createNewSell({ ...this.props.sell, total: this.getTotal(), payment: this.state.payment.value, delivery: this.state.delivery, out: this.state.out, note: this.state.note, discount: this.state.discount, tip: { type: this.state.tip.type.value, amount: getNumber(this.state.tip.amount) } })
                    return true
                }
            } else {
                this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Creación cancelada, ingrese medio de pago" }))
                return false
            }
        }
        this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Creación cancelada, ingrese medio de pago" }))
        return false
    }
    getTotal = () => {
        const { total2 = 0, total3 = 0, total4 = 0 } = this.props.info
        return parseInt(parseInt(total2 + total3 + total4) * (100 - this.state.discount) / 100)
    }
    getHeaders = () => {
        const toReturn = [{
            Header: "Cliente",
            accessor: "client"
        }]

        const { promotions = [], wrappers = [], products = [] } = this.props.info

        if (wrappers.length)
            toReturn.push({
                Header: "Rolls",
                accessor: "wrappers"
            })
        if (products.length)
            toReturn.push({
                Header: "Productos",
                accessor: "products"
            })
        if (promotions.length)
            toReturn.push({
                Header: "Promociones",
                accessor: "promotions"
            })

        toReturn.push({
            Header: "Total",
            accessor: "total"
        })

        return toReturn;
    }
    getData = () => {
        const { client = {}, promotions = [], wrappers = [], products = [] } = this.props.info
        return [{
            client: <div className="d-flex flex-column">
                <Badge color="success">{client.name}</Badge>
                <Badge color="success">+56(9){client.phone}</Badge>
                <Badge color="success">{client.address}</Badge>
                <Badge color="success">{client.email}</Badge>
            </div>,
            wrappers: wrappers.map(({ label = "", ingredients = [] }, m) => {
                return (<div className="d-flex flex-column" key={m}>
                    <Badge color="success">{label}</Badge>
                    {ingredients.map((name = "", z) => <Badge key={z} color="warning">{name}</Badge>)}
                </div>)
            }),
            total: <div className="d-flex flex-column"> <h1 className="text-center">{numberToCLPFormater(this.getTotal() )}</h1><h2 className="text-center">Propina:{numberToCLPFormater(getNumber(this.state.tip.amount))}</h2><h2 className="text-center">Total:{numberToCLPFormater(this.getTotal() + getNumber(this.state.tip.amount))}</h2> </div>,
            products: <div className="d-flex flex-column">{products.map(({ name = "", amount = 0 }, l) => <Badge key={l} color="primary">{`[${amount}]:${name}`}</Badge>)}</div>,
            promotions:
                promotions.map(({ name = "", wrappers = [], products = [] }, k) => {
                    return (<div key={k} className="border d-flex flex-column border-primary mb-2">
                        <h3 className="info-text">{name.toUpperCase()}</h3>
                        {products.map((prod = "", x) => <Badge key={x} color="primary">{prod}</Badge>)}
                        {wrappers.map(({ name = "", ingredients }, y) => {
                            return (< div className="d-flex flex-column" key={y}>
                                <Badge color="success">{name}</Badge>
                                {!ingredients.length ? <Badge color="warning">A elección del chef</Badge> :
                                    ingredients.map((name = "", a) => <Badge key={a} color="warning">{name}</Badge>)
                                }
                            </div>)
                        })}
                    </div>)

                })
        }]
    }
    render() {
        return (
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Resumen venta:</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <ReactTable
                                            data={this.getData()}
                                            columns={this.getHeaders()}
                                            defaultPageSize={1}
                                            showPagination={false}
                                            className="-striped -highlight"
                                            previousText='Anterior'
                                            nextText='Siguiente'
                                            loadingText='Cargando...'
                                            noDataText='No existen datos'
                                            pageText='Página'
                                            ofText='de'
                                            rowsText='elementos'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2} className="mt-2">
                                        <FormGroup className="d-flex flex-column">
                                            <label> Método de pago:</label>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                name="payment"
                                                value={this.state.payment}
                                                onChange={value =>
                                                    this.setState({ payment: value })
                                                }
                                                options={[
                                                    { value: "debito", label: "Débito" }, { value: "credito", label: "Crédito" }, { value: "efectivo", label: "Efectivo" },
                                                    { value: "transferencia", label: "Transferencia" }, { value: "cheque", label: "Cheque" }, { value: "rappi", label: "Rappi" }
                                                ]}
                                                placeholder="Seleccione el método de pago"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2} className="mt-2">
                                        <FormGroup className="d-flex flex-column">
                                            <label> Tipo de propina:</label>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                name="tip"
                                                value={this.state.tip.type}
                                                onChange={value =>
                                                    this.setState({ tip: { type: value, amount: this.state.tip.amount } })
                                                }
                                                options={[
                                                    { value: "cash", label: "Efectivo" },
                                                    { value: "transbank", label: "Transbank" },
                                                    { value: "transfer", label: "Transferencia" }
                                                ]}
                                                placeholder="Seleccione el método propina"
                                            />
                                            <label> Monto de propina:</label>
                                            <Input 
                                            value={numberToCLPFormater(this.state.tip.amount)} 
                                            onChange={({ target: { value } }) => this.setState({ tip: { type: this.state.tip.type, amount: getNumber(value) } })} 
                                             name="amountTip" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2} className="mt-3 text-center">
                                        <FormGroup className="d-flex flex-column">
                                            <label> ¿Reparto?</label>
                                            <Switch
                                                wrapperClass='wrapper mx-auto'
                                                offText={<i className="tim-icons icon-simple-remove" />}
                                                onText={<i className="tim-icons icon-check-2" />}
                                                value={this.state.delivery}
                                                onChange={({ props: { name } }, value) => this.setState({ [name]: value })}
                                                name="delivery" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2} className="mt-3">
                                        <FormGroup className="d-flex flex-column">
                                            <label>Hora de entrega</label>
                                            <ReactDatetime
                                                value={this.state.out}
                                                dateFormat={false}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Time Picker Here"
                                                }}
                                                onChange={e => this.setState({ out: (e instanceof moment) ? e.toDate() : ((new RegExp('^([01]?[0-9]|2[0-3]):[0-5][0-9]$').test(e)) ? moment().toDate().setHours(parseInt(e.split(':')[0])).setMinutes(parseInt(e.split(':')[1])) : this.state.out) })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2} className="mt-3">
                                        <FormGroup className="d-flex flex-column">
                                            <label>Descuento (%)</label>
                                            <Input value={this.state.discount} onChange={({ target: { value } }) => this.setState({ discount: (value >= 0 && value <= 100) ? value : this.state.discount })} type="number" name="discount" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2} className="mt-3">
                                        <FormGroup className="d-flex flex-column">
                                            <label>Nota</label>
                                            <Input value={this.state.note} onChange={({ target: { value } }) => this.setState({ note: value })} type="textarea" name="text" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}