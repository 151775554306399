import React, { useContext, useEffect, useState } from 'react'
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button, Badge } from "reactstrap";
import Select from "react-select";

import WrapperProvider, { WrapperContext } from '@Contexts/wrapper'
import IngredientProvider, { IngredientContext } from '@Contexts/ingredient'
import FormProvider, { FormContext } from '@Contexts/form'

const Suggesteds = () => {
    const
        { suggesteds, getSuggested, setSuggested } = useContext(WrapperContext),
        { ingredients } = useContext(IngredientContext),
        { values: { MyAlert, editId, selectSuggest }, setMyStates } = useContext(FormContext),
        [ready, setReady] = useState(false)

    useEffect(() => {
        async function getWrappers() {
            setReady(true)
            await getSuggested()
        }
        if (!ready) getWrappers()
    }, [getSuggested, ready])

    const submit = async () => {
        await setSuggested({
            _id: editId, suggested: selectSuggest
        })
        setMyStates({ editId: "" })
    }

    const makeSelect = (n, _id) => {
        const data = ingredients.map(({ _id, name, type }) => (type === "tipo " + n ? { label: name, value: _id } : null)).filter(a => a != null)
        return <Select
            className="react-select info"
            classNamePrefix="react-select"
            menuPosition="fixed"
            options={data}
            value={selectSuggest[n - 1]}
            onChange={v => setMyStates({
                selectSuggest:
                    selectSuggest.slice(0, (n - 1))
                        .concat(v)
                        .concat(selectSuggest.slice(n))
            })}
        />
    }

    const
        getData = () => {
            return suggesteds.map(({ name, ingredients, _id, suggesteds }) => {
                return {
                    name,
                    ingredients,
                    suggested: (
                        editId === _id ?
                            <div className="d-flex flex-column">
                                {ingredients >= 1 ?
                                    makeSelect(1, _id)
                                    : null}
                                {ingredients >= 2 ?
                                    makeSelect(2, _id)
                                    : null}
                                {ingredients >= 3 ?
                                    makeSelect(3, _id)
                                    : null}
                                {ingredients >= 4 ?
                                    makeSelect(4, _id)
                                    : null}
                            </div>
                            :
                            <div className="d-flex flex-column">{suggesteds.map(({ name }, k) => <Badge key={k} color="primary">{name}</Badge>)}</div>
                    )
                    ,
                    actions: (
                        editId === _id ?
                            <div className="actions-right">
                                <Button onClick={submit} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-check-2 bg-success" />
                                </Button>
                                <Button onClick={() =>
                                    setMyStates({
                                        editId: "",
                                        selectSuggest: [{}, {}, {}, {}]
                                    })} className="btn-icon btn-link like">
                                    <i className="tim-icons icon-simple-remove bg-danger" />
                                </Button>
                            </div>
                            :
                            <div className="actions-right">
                                <Button onClick={() => setMyStates({
                                    editId: _id, selectSuggest: [
                                        suggesteds[0] ? { label: suggesteds[0].name, value: suggesteds[0]._id } : {},
                                        suggesteds[1] ? { label: suggesteds[1].name, value: suggesteds[1]._id } : {},
                                        suggesteds[2] ? { label: suggesteds[2].name, value: suggesteds[2]._id } : {},
                                        suggesteds[3] ? { label: suggesteds[3].name, value: suggesteds[3]._id } : {}
                                    ]
                                })} className="btn-icon btn-link like mr-2">
                                    <i className="tim-icons icon-pencil bg-success" />
                                </Button>
                            </div>
                    )
                }
            })

        },
        getColums = () => {
            return [
                {
                    Header: "Nombre",
                    accessor: "name",
                    className: "text-center"
                },
                {
                    Header: "Cantidad de ingredientes",
                    accessor: "ingredients",
                    className: "text-center",
                    sortable: false,
                    filterable: false
                },
                {
                    Header: "Sugeridos",
                    accessor: "suggested",
                    className: "text-center",
                    sortable: false,
                    filterable: false
                },
                {
                    Header: "Acciones",
                    accessor: "actions",
                    className: "text-center",
                    sortable: false,
                    filterable: false
                }
            ]
        }

    return (
        <div className="content">
            {MyAlert}
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Lista de sugeridos</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={getData()}
                                filterable
                                columns={getColums()}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No existen datos'
                                pageText='Página'
                                ofText='de'
                                rowsText='elementos'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )


}


export default ({ history }) => (
    <WrapperProvider>
        <IngredientProvider>
            <FormProvider initialValues={{ MyAlert: null, editId: "", selectSuggest: [{}, {}, {}, {}] }}>
                <Suggesteds history={history} />
            </FormProvider>
        </IngredientProvider>
    </WrapperProvider>
)