import React from 'react'
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap'
import { numberToCLPFormater } from 'numbertoclpformater'
import Select from "react-select";
import { getCancelAlert } from '@Config/util'

const emptyWrapper = { ingredients: [], selectWrapper: {} }
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wrappers: []
        };
    }
    isValidated = () => {
        let exit = true, total = 0, totalWrappers = []
        const ings = this.state.wrappers.map(({ ingredients, selectWrapper }, wp) => {
            totalWrappers.push({ label: selectWrapper.label.replace('Envuelto en ', '').split(':')[0], ingredients: [] })
            let ing = ingredients.map(({ value, label }) => {
                if (!value || !label) exit = false
                totalWrappers[wp].ingredients.push(label)
                return value
            })
            if (!selectWrapper.value) {
                exit = false
                return null
            }
            const a = this.props.wrappers.filter(({ _id }) => _id === selectWrapper.value)[0]
            total += a.price
            return { wrapper: selectWrapper.value, ingredients: ing }
        })
        if (exit) {
            this.props.setSell({ ...this.props.sell, wrappers: ings })
            this.props.setInfo({ ...this.props.info, wrappers: totalWrappers, total3: total })
            return true
        } else {
            this.props.setAlert(getCancelAlert({ hideAlert: this.props.hideAlert, msg: "Debe llenar todos los campos." }))
        }
    }
    removeWrapper = nWrapper => () => this.setState({ wrappers: this.state.wrappers.slice(0, nWrapper).concat(this.state.wrappers.slice(nWrapper + 1)) })
    addWrapper = () => this.setState({ wrappers: this.state.wrappers.concat(emptyWrapper) })
    changeWrapper = nWrapper => selectWrapper => {
        if (!selectWrapper.value && !selectWrapper.label) return
        const wrapper = this.props.wrappers.filter(({ _id }) => _id === selectWrapper.value)[0]
        let ingredients = []
        for (let i = 0; i < wrapper.ingredients; i++) { ingredients.push(wrapper.suggesteds[i] ? { label: wrapper.suggesteds[i].name, value: wrapper.suggesteds[i]._id } : {}) }
        this.setState({
            wrappers: this.state.wrappers.slice(0, nWrapper)
                .concat({ selectWrapper, ingredients })
                .concat(this.state.wrappers.slice(nWrapper + 1))
        })
    }
    getIngredients = (nWrap, nIngr) => {
        const ings = this.props.ingredients.filter(({ type }) => type === "tipo " + (nIngr + 1))
        return <Select
            key={nWrap + nIngr + 1}
            className="react-select info mb-2"
            classNamePrefix="react-select"
            name={"select_ingredient" + nWrap + nIngr}
            value={this.state.wrappers[nWrap].ingredients[nIngr]}
            onChange={val => {
                this.setState({
                    wrappers: this.state.wrappers.slice(0, nWrap)
                        .concat({
                            ...this.state.wrappers[nWrap],
                            ingredients: this.state.wrappers[nWrap].ingredients.slice(0, nIngr)
                                .concat(val)
                                .concat(this.state.wrappers[nWrap].ingredients.slice(nIngr + 1))
                        })
                        .concat(this.state.wrappers.slice(nWrap + 1))
                })
            }}
            options={ings.map(({ name, _id }) => ({ label: name, value: _id }))}
            placeholder="Elija un roll"
        />
    }

    render() {

        const { wrappers } = this.state
        return (
            <div className="content">
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <Card>
                            <CardHeader><h3 className="title">¿Desea añadir algun Roll?</h3></CardHeader>
                            <CardBody>
                                <Row>
                                    {wrappers.map(({ ingredients }, k) => (
                                        <Col md={12} key={k}>
                                            <Row>
                                                <Col md={5}>
                                                    <Select
                                                        className="react-select info mb-2"
                                                        classNamePrefix="react-select"
                                                        name={"select_wrapper" + k}
                                                        value={wrappers[k].selectWrapper}
                                                        onChange={this.changeWrapper(k)}
                                                        options={this.props.wrappers.map(({ name, price, _id }) => ({ label: ` Envuelto en ${name}: ${numberToCLPFormater(price)}`, value: _id }))}
                                                        placeholder="Elija un roll"
                                                    />
                                                </Col>
                                                <Col md={5} className="d-flex flex-column" >
                                                    {ingredients.map((_, n) => this.getIngredients(k, n))}
                                                </Col>
                                                <Col>  <Button onClick={this.removeWrapper(k)} className="btn-icon btn-link like"><i className="tim-icons icon-simple-remove bg-danger" /></Button></Col>
                                            </Row>
                                        </Col>
                                    ))}
                                    <Col className="my-3 text-center" md={12}> <Button onClick={this.addWrapper}>Añadir</Button> </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}