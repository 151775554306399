// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav, Collapse } from "reactstrap";
import { useCookies } from 'react-cookie';


const
  getCollapseStates = routes => {
    let initialState = {}
    // eslint-disable-next-line
    routes.map(({ collapse, state, views }) => {
      if (collapse) initialState = { [state]: getCollapseInitialState(views), ...getCollapseStates(views), ...initialState }
    })
    return initialState
  },
  getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) return true
      else if (window.location.href.indexOf(routes[i].path) !== -1) return true;
    }
    return false;
  }

const Sidebar = ({ routes, location, closeSidebar, logo, activeColor, history }) => {
  // Initial states
  const [{ userData }, ,] = useCookies(['userData']);

  const [myState, setState] = useState(getCollapseStates(routes)),
    [sidebarRef, setSidebarRef] = useState(null),
    sidebarCB = useCallback(n => setSidebarRef(n), [setSidebarRef])
  // Setting create and destroy component
  useEffect(() => {
    let ps = null;
    if (navigator.platform.indexOf("Win") > -1 && sidebarRef) ps = new PerfectScrollbar(sidebarRef);
    return () => {
      if (navigator.platform.indexOf("Win") > -1 && ps && sidebarRef) ps.destroy();
    }
  }, [sidebarRef])
  // Functions
  const
    activeRoute = routeName => location.pathname.indexOf(routeName) > -1 ? "active" : "",
    createLinks = routes => {
      return routes.map(({ exclude, redirect, collapse, state, views, icon, mini, name, layout, path, type }, key) => {
        if (!userData ) return ('/auth/login')
        if (!userData.type || !userData.token) return history.push('/auth/login')
        if(exclude) return null
        if (!type.includes(userData.type)) return null
        if (redirect) return null;
        if (collapse) {
          const st = {};
          st[state] = !myState[state];
          return (
            <li className={getCollapseInitialState(views) ? "active" : ""} key={key}>
              <a href="#a" data-toggle="collapse" aria-expanded={myState[state]} onClick={e => { e.preventDefault(); setState(st); }}>
                {icon ? <><i className={icon} /><p>{name}<b className="caret" /></p></> :
                  <><span className="sidebar-mini-icon">{mini}</span><span className="sidebar-normal">{name}<b className="caret" /></span></>}
              </a>
              <Collapse isOpen={myState[state]}><ul className="nav">{createLinks(views)}</ul></Collapse>
            </li>
          );
        }
        return (
          <li className={activeRoute(layout + path)} key={key}>
            <NavLink to={layout + path} activeClassName="" onClick={closeSidebar}>
              {icon ? <><i className={icon} /><p>{name}</p></> :
                <><span className="sidebar-mini-icon">{mini}</span><span className="sidebar-normal">{name}</span></>}
            </NavLink>
          </li>
        );
      });
    },
    myLogo = () => {
      let logoImg, logoText
      if (logo) {
        if (logo.outterLink) {
          logoImg = (
            <a href={logo.outterLink} rel="noopener noreferrer" className="simple-text logo-mini" target="_blank" onClick={closeSidebar}>
              <div className="logo-img">
                <img src={logo.imgSrc} alt="react-logo" />
              </div>
            </a>
          );
          logoText = (
            <a href={logo.outterLink} rel="noopener noreferrer" className="simple-text logo-normal" target="_blank" onClick={closeSidebar}>
              {logo.text}
            </a>
          );
        } else {
          logoImg = (
            <NavLink to={logo.innerLink} className="simple-text logo-mini" onClick={closeSidebar}>
              <div className="logo-img">
                <img src={logo.imgSrc} alt="react-logo" />
              </div>
            </NavLink>
          );
          logoText = (
            <NavLink to={logo.innerLink} className="simple-text logo-normal" onClick={closeSidebar}>
              {logo.text}
            </NavLink>
          );
        }
      }
      return <div className="logo">{logoImg}{logoText}</div>
    }
  // Rendering
  return (
    <div className="sidebar" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarCB}>
        {myLogo()}
        <Nav>{createLinks(routes)}</Nav>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func
};

export default Sidebar;
