export const lineConfigDataset = gradientStroke => ({
    label: "Venta Mensual",
    fill: true,
    backgroundColor: gradientStroke,
    borderColor: "#1f8ef1",
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: "#1f8ef1",
    pointBorderColor: "rgba(255,255,255,0)",
    pointHoverBackgroundColor: "#1f8ef1",
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4
})

export const setCanvas = gradientStroke => {
    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)");
}

export const lineConfig = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
    },
    responsive: true,
    scales: {
        yAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent"
                },
                ticks: {
                    suggestedMin: 60,
                    suggestedMax: 125,
                    padding: 20,
                    fontColor: "#9a9a9a"
                }
            }
        ],
        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.1)",
                    zeroLineColor: "transparent"
                },
                ticks: {
                    padding: 20,
                    fontColor: "#9a9a9a"
                }
            }
        ]
    }
}

export const pieConfig = {
    cutoutPercentage: 70,
    legend: {
        display: false
    },
    tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
    },
    scales: {
        yAxes: [
            {
                display: 0,
                ticks: {
                    display: false
                },
                gridLines: {
                    drawBorder: false,
                    zeroLineColor: "transparent",
                    color: "rgba(255,255,255,0.05)"
                }
            }
        ],
        xAxes: [
            {
                display: 0,
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(255,255,255,0.1)",
                    zeroLineColor: "transparent"
                },
                ticks: {
                    display: false
                }
            }
        ]
    }
}

export const pieConfigDataset = {
    label: "Emails",
    pointRadius: 0,
    pointHoverRadius: 0,
    backgroundColor: e => {
        return '#' + ("000000" + Math.random().toString(16).slice(2, 8).toUpperCase()).slice(-6);
    },
    borderWidth: 0,
}

const names = ["Efectivo", "Débito", "Crédito", "Transferencias", "Descuentos totales", "Total", "Propinas totales","Cantidad de descuentos:", "Cantidad de transferencias", "Cantidad de transacciones"]

export const mixedConfig = numberToCLPFormater => ({
    responsive: true,
    legend: {
        display: false
    },
    type: "bar",
    tooltips: {
        callbacks: {
            label: ({ datasetIndex, yLabel }) => `${names[datasetIndex]}  ${datasetIndex > 6 ? yLabel : numberToCLPFormater(yLabel)}`
        }
    },
    scales: {
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    beginAtZero: true,
                    callback: value => numberToCLPFormater(parseInt(value))
                },
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            },
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            }
        ]
    }
})