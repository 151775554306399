import React, { useContext, useState } from 'react'
import ReactWizard from "react-bootstrap-wizard";
import { Col } from "reactstrap";

import ClientProvider, { ClientContext } from '@Contexts/client'
import SellProvider, { SellContext } from '@Contexts/sell'
import WrapperProvider, { WrapperContext } from '@Contexts/wrapper'
import PromotionProvider, { PromotionContext } from '@Contexts/promotion'
import ProductProvider, { ProductContext } from '@Contexts/product'
import IngredientProvider, { IngredientContext } from '@Contexts/ingredient'
import FormProvider, { FormContext } from '@Contexts/form'

import Step1 from './Steps/1'
import Step2 from './Steps/2'
import Step3 from './Steps/3'
import Step4 from './Steps/4'
import Step5 from './Steps/5'
import { getCancelAlert } from '@Config/util'

import './style.css'

const Wizard = ({ history }) => {
    const { clients, addClient, modifyClient } = useContext(ClientContext),
        { sell, setSell, addSell } = useContext(SellContext),
        { promotions } = useContext(PromotionContext),
        { wrappers } = useContext(WrapperContext),
        { products } = useContext(ProductContext),
        { ingredients } = useContext(IngredientContext),
        { values: { MyAlert }, setMyStates } = useContext(FormContext),
        [info, setInfo] = useState({}),
        [alert, setAlert] = useState(null),
        hideAlert = () => setAlert(null)

    const createNewSell = ({ client = "", products = [], wrappers = [], promotions = [], payment = "", total = 0, delivery = false, out = new Date(), note = "", discount = 0, tip = { type: "cash", amount: 0 } }) => {
        addSell({ tip, client, products, wrappers, promotions, payment, total, delivery, out, note, discount: parseInt(discount) }).then(data => { console.log(data); history.push({ pathname: '/print', state: data.state }) }).catch(e => { console.log('err', e); setAlert(getCancelAlert({ hideAlert: hideAlert, msg: e.msg })) })
    }

    return <div className="content">
        {alert}
        {MyAlert}
        <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
                steps={
                    [
                        {
                            stepName: "Cliente",
                            stepIcon: "tim-icons icon-single-02",
                            component: Step1,
                            stepProps: { clients, addClient, modifyClient, setSell, setAlert, hideAlert, setInfo }
                        },
                        {
                            stepName: "Promociones",
                            stepIcon: "tim-icons icon-gift-2",
                            component: Step2,
                            stepProps: { promotions, ingredients, setSell, setAlert, hideAlert, sell, info, setInfo, wrappers, products }
                        },
                        {
                            stepName: "Envolturas",
                            stepIcon: "tim-icons icon-link-72",
                            component: Step3,
                            stepProps: { ingredients, wrappers, setSell, setAlert, hideAlert, sell, info, setInfo }
                        },
                        {
                            stepName: "Productos",
                            stepIcon: "tim-icons icon-attach-87",
                            component: Step4,
                            stepProps: { setMyStates, products, setSell, sell, favorites: products.filter(({ favorite }) => favorite).sort((a, b) => a.price > b.price ? 1 : -1), info, setInfo }
                        },
                        {
                            stepName: "Resumen",
                            stepIcon: "tim-icons icon-paper",
                            component: Step5,
                            stepProps: { products, wrappers, ingredients, promotions, setSell, setAlert, hideAlert, sell, addSell, info, createNewSell }
                        }
                    ]
                }

                validate
                title="Crear venta"
                description="Venta asistida para sushi."
                headerTextCenter
                previousButtonText="Anterior"
                finishButtonText="Terminar"
                nextButtonText="Siguiente"
                finishButtonClasses="btn-wd btn-info"
                nextButtonClasses="btn-wd btn-info"
                previousButtonClasses="btn-wd"
                progressbar
                color="blue"
            />
        </Col>
    </div>
}

export default (props) => <IngredientProvider><FormProvider initialValues={{ MyAlert: null }}><WrapperProvider><PromotionProvider><ProductProvider><SellProvider><ClientProvider><Wizard {...props} /></ClientProvider></SellProvider></ProductProvider></PromotionProvider></WrapperProvider></FormProvider></IngredientProvider>