import React, { createContext, useState, useEffect } from 'react'
import { element } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks';

import Mutations from '@Graphql/ingredient'
import { errorHandler, lookError } from '@Config/util'

export const IngredientContext = createContext({
    addIngredient: async () => undefined,
    modifyIngredient: async () => undefined,
    removeIngredient: async () => undefined,
    ingredients: []
})

const IngredientProvider = ({ children }) => {

    const { mutate, query, resetStore } = useApolloClient(),
        [ingredients, setIngredients] = useState([]),
        [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            async function getIngredients() {
                try {
                    await resetStore()
                    const { data } = await query({ query: Mutations.GET_INGREDIENTS })
                    setIngredients(data.getIngredients)
                } catch (e) {
                    return lookError(e)
                }
            }
            getIngredients();
            setLoaded(true)
        }
    }, [setLoaded, loaded, query, setIngredients, resetStore])

    async function addIngredient({ name, type, weight, alias }) {
        try {
            const { data: { addIngredient } } = await mutate({
                mutation: Mutations.ADD_INGREDIENT,
                variables: { name, type, weight, alias }
            })
            setIngredients(ingredients.concat(addIngredient))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function modifyIngredient({ name, type, _id, weight, alias }) {
        try {
            await mutate({
                mutation: Mutations.MODIFY_INGREDIENT,
                variables: { name, type, _id, weight, alias }
            })
            const index = ingredients.map(e => e._id).indexOf(_id)
            setIngredients(ingredients.slice(0, index).concat({ name, type, _id, weight, alias }).concat(ingredients.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    async function removeIngredient({ _id }) {
        try {
            await mutate({
                mutation: Mutations.REMOVE_INGREDIENT,
                variables: { _id }
            })
            const index = ingredients.map(e => e._id).indexOf(_id)
            setIngredients(ingredients.slice(0, index).concat(ingredients.slice(index + 1)))
            return { ok: true }
        } catch (e) {
            return errorHandler(e)
        }
    }

    const context = {
        addIngredient,
        ingredients,
        modifyIngredient,
        removeIngredient
    }

    return (
        <IngredientContext.Provider value={context}>
            {children}
        </IngredientContext.Provider>
    )
}

IngredientProvider.propTypes = {
    children: element
}

export default IngredientProvider