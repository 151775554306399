import { gql } from 'apollo-boost'

export default {
    GET_PRODUCTS: gql`
        query getProducts {
            getProducts {
                _id
                name
                price
                favorite
                barcode
                weight{
                    name
                    amount
                    unity
                }
            }
        }    
    `,
    ADD_PRODUCT: gql`
        mutation addProduct($name: String!, $price: Int!, $weight: [WeightInput!]!, $favorite: Boolean!, $barcode: String) {
            addProduct(input: {name: $name, price: $price, weight: $weight, favorite: $favorite, barcode: $barcode}){
                _id
                name
                price
                favorite
                barcode
                weight{
                    name
                    amount
                    unity
                }
            }
        }
    `,
    MODIFY_PRODUCT: gql`
        mutation modifyProduct($name: String!, $price: Int!, $weight: [WeightInput!]!, $_id: ObjectId!, $favorite: Boolean!, $barcode: String) {
            modifyProduct(input:{name: $name, price: $price, weight: $weight, _id: $_id, favorite: $favorite, barcode: $barcode})
        }
    `,
    REMOVE_PRODUCT: gql`
        mutation removeProduct($_id: ObjectId!) {
            removeProduct(input: $_id)
        }
    `
}