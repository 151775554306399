// Dependencies
import React, { useState } from "react";
import Switch from "react-bootstrap-switch";

export default ({toggleColor, activeColor, handleActiveClick, handleMiniClick, sidebarMini}) => {
  // Initial states
  const [{ classes, darkMode }, setState] = useState({ classes: "dropdown", darkMode: false })
  // Functions
  const
    handleClick = e => {
      e.preventDefault()
      setState({ darkMode, classes: classes === "dropdown" ? "dropdown show" : "dropdown" })
    },
    handleActiveMode = () => {
      setState({ darkMode: !darkMode, classes })
      toggleColor()
    }
  // Rendering
  return (
    <div className="fixed-plugin">
      <div className={classes}>
        <div onClick={handleClick}><i className="fa fa-cog fa-2x" /></div>
        <ul className="dropdown-menu show py-4">
          <li className="header-title">Color menú lateral</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={activeColor === "primary" ? "badge filter badge-primary active" : "badge filter badge-primary"}
                data-color="primary" onClick={handleActiveClick("primary")} />
              <span
                className={activeColor === "blue" ? "badge filter badge-info active" : "badge filter badge-info"}
                data-color="info" onClick={handleActiveClick("blue")} />
              <span
                className={activeColor === "green" ? "badge filter badge-success active" : "badge filter badge-success"}
                data-color="success" onClick={handleActiveClick("green")} />
              <span
                className={activeColor === "orange" ? "badge filter badge-warning active" : "badge filter badge-warning"}
                data-color="warning" onClick={handleActiveClick("orange")} />
              <span
                className={activeColor === "red" ? "badge filter badge-danger active" : "badge filter badge-danger"}
                data-color="danger" onClick={handleActiveClick("red")} />
            </div>
          </li>
          <li className="header-title">Menú lateral pequeño</li>
          <li className="adjustments-line">
            <div className="togglebutton switch-sidebar-mini">
              <span className="label-switch">Desactivado</span>
              <Switch onChange={handleMiniClick} value={sidebarMini} onText="" offText="" />
              <span className="label-switch">Activado</span>
            </div>
          </li>
          <li className="adjustments-line">
            <div className="togglebutton switch-change-color mt-3">
              <span className="label-switch">Modo luz</span>
              <Switch onChange={handleActiveMode} value={darkMode} onText="" offText="" />
              <span className="label-switch">Modo oscuro</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}