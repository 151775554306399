// Dependencies
import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

const Footer = ({ fluid, footerDefault }) => {
  //Rendering
  return (
    <footer className={"footer" + (footerDefault ? " footer-default" : "")}>
      <Container fluid={fluid ? true : false}>
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="https://www.inforser.cl">Inforser</a>
          </li>{" "}
          <li className="nav-item">
            <a className="nav-link" href="https://pymes.inforser.cl">Pymes</a>
          </li>{" "}
        </ul>
        <div className="copyright">
          © {new Date().getFullYear()} hecho con{" "} <i className="tim-icons icon-heart-2" /> por{" "}<a href="https://www.inforser.cl">Inforser</a>{" "} Pioneros en la web.
          </div>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  footerDefault: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
