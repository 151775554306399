import { gql } from 'apollo-boost'

export default {
    AUTH: gql`
        mutation auth($email: String!, $password: String!) {
            auth(input: {email: $email, password: $password}) {
                token
                type
                _id
            }
        }
    `,
    GET_USERS: gql`
        query getUsers{
            getUsers {
                _id
                name
                email
                password
                type
            }
        }
    `,
    ADD_USER: gql`
      mutation addUser($name: String!, $email: String!, $password: String!, $type: String!) {
          addUser(input: {name: $name, email: $email, password: $password, type: $type}){
              _id
              name
              email
              password
              type
          }
      }
  `,
    MODIFY_USER: gql`
      mutation modifyUser($name: String!, $email: String!, $password: String!, $type: String!, $_id: ObjectId!) {
          modifyUser(input: {name: $name, email: $email, password: $password, type: $type, _id: $_id})
      }
  `,
    REMOVE_USER: gql`
      mutation removeUser($_id: ObjectId!) {
          removeUser(input: $_id)
      }
`
}