import { gql } from 'apollo-boost'

export default {
    GET_INGREDIENTS: gql`
        query getIngredients {
            getIngredients {
                _id
                name
                type
                alias
                weight{
                    name
                    amount
                    unity
                }
            }
        }    
    `,
    ADD_INGREDIENT: gql`
        mutation addIngredient($name: String!, $type: String!, $weight: [WeightInput!]!, $alias: String!) {
            addIngredient(input: {name: $name, type: $type, weight: $weight, alias: $alias}){
                _id
                name
                type
                alias
                weight{
                    name
                    amount
                    unity
                }
            }
        }
    `,
    MODIFY_INGREDIENT: gql`
        mutation modifyIngredient($name: String!, $type: String!, $weight: [WeightInput!]!, $_id: ObjectId!, $alias: String!) {
            modifyIngredient(input:{name: $name, type: $type, weight: $weight, _id: $_id, alias: $alias})
        }
    `,
    REMOVE_INGREDIENT: gql`
        mutation removeIngredient($_id: ObjectId!) {
            removeIngredient(input: $_id)
        }
    `
}